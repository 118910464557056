import { makeAuthRequest } from "./auth";

export const getEvents = async (query) => {
  let url = '/events';
  if (query) url += `?query=${query}`;
  return await makeAuthRequest(url, 'GET');
}

export const getEvent = async (id) => {
  return await makeAuthRequest(`/events/${id}`, 'GET');
}

export const getEventInvitees = async (id) => {
  return await makeAuthRequest(`/events/${id}/invites`, 'GET')
}

export const createEvent = async (event) => {
  return await makeAuthRequest('/events', 'POST', event);
}

export const recreateEvent = async (eventId) => {
  return await makeAuthRequest(`/events/recreate/${eventId}`, 'POST');
}

export const deleteEvent = async (eventId) => {
  return await makeAuthRequest(`/events/${eventId}`, 'DELETE');
}

export const getEventRsvps = async (id) => {
  return await makeAuthRequest(`/events/${id}/event_attendees`, 'GET');
}

export const updateEvent = async (event, id) => {
  return await makeAuthRequest(`/events/${id}`, 'PATCH', event);
}

export const getEventItems = async (id) => {
  return await makeAuthRequest(`/events/${id}/event_items`, 'GET');
}

export const getContributedItemCount = async () => {
  return await makeAuthRequest('/items/total_count', 'GET');
}

export const deleteEventItem = async (id) => {
  return await makeAuthRequest(`/event_items/${id}`, 'DELETE');
}

export const createEventItem = async (description, eventId) => {
  return await makeAuthRequest(`/events/${eventId}/event_items`, 'POST', { description });
}

export const updateEventItem = async (eventId, itemId, type) => {
  return await makeAuthRequest(`/events/${eventId}/event_items/${itemId}`, 'PATCH', { type })
}

export const sendInvites = async (eventId, contacts) => {
  return await makeAuthRequest(`/events/${eventId}/send_invites`, 'POST', { contacts });
}

export const uninvite = async (rsvpId) => {
  return await makeAuthRequest(`/event_attendees/${rsvpId}`, 'DELETE');
}

export const respondToInvite = async (rsvpId, response) => {
  return await makeAuthRequest(`/event_attendees/${rsvpId}/respond`, 'PATCH', { response });
}

export const getChatMessages = async (eventId) => {
  return await makeAuthRequest(`/events/${eventId}/messages`, 'GET');
}

export const getEventAnnouncements = async (eventId) => {
  return await makeAuthRequest(`/events/${eventId}/announcements`, 'GET');
}

export const requestEventAccess = async (eventId) => {
  return await makeAuthRequest(`/events/${eventId}/request_access`, 'POST');
}
