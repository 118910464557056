import React from 'react';
import { FlatList } from 'react-native';

import Empty from '../../Empty';
import NotificationItem from './NotificationItem';

import languageSpecificText from '../../../helpers/languages';

export default function NotificationList({ notificationState }) {
  const renderNotification = (obj) => {
    const notif = obj.item;
    return <NotificationItem notification={notif} key={obj.index} />
  }

  return (
    <FlatList
      data={notificationState}
      renderItem={renderNotification}
      keyExtractor={obj => obj.id}
      ListEmptyComponent={
        <Empty
          iconFile="shrug"
          texts={[
            languageSpecificText('notifications', 'empty'),
            languageSpecificText('notifications', 'types')
          ]}
        />
      }
    />
  )
}
