import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native-web';

import { requestEventAccess } from '../../../utils/api/events';
import styleGuide from '../../../assets/styleGuide';
import Button from '../../Button';
import Flash from '../../Flash';
import Empty from '../../Empty';
const { distance, fontSize, colors } = styleGuide;

export default function RequestAccess({ event }) {
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState(null);
  const [buttonText, setButtonText] = useState('Request Access')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    const requested = event.rsvp?.access === 'requested';
    if (requested) {
      setButtonText('Access Requested');
      setButtonDisabled(true);
    }
  }, [event.rsvp?.access]);

  const handleAccessRequest = async () => {
    const res = await requestEventAccess(event.id);
    if (res.success) {
      setSuccess(true)
      setMessage('Successfully requested access')
      setButtonText('Access Requested')
      setButtonDisabled(true)
    } else {
      setSuccess(false)
      setMessage(res.message || 'Failed to request access')
    }
  }

  return (
    <View style={styles.container}>
      <View style={{ width: `calc(100% - ${distance.large * 2})`, zIndex: 100 }}>
        {message && <Flash messages={[message]} success={success} onExit={() => setMessage(null)} />}
      </View>

      <View style={{ ...styles.panel, marginBottom: distance.large }}>
        <View style={styles.textSectionWrapper}>
          <Text style={styles.smallText}>EVENT</Text>
          <Text style={styles.mainText}>{event.title}</Text>
        </View>
        <View style={{ ...styles.textSectionWrapper, marginTop: distance.medium }}>
          <Text style={styles.smallText}>HOST</Text>
          <Text style={styles.mainText}>{event.creator.name}</Text>
        </View>
      </View>
      <View style={styles.panel}>
        <Empty
          showLink={false}
          iconFile="shrug"
          texts={[
            'You do no have access to the event, request it here!',
            '',
            'When the host replies to your request you\'ll receive a notification (email or push, based on your settings).'
          ]}
        />
      </View>
      <Button
        async
        title={buttonText}
        disabled={buttonDisabled}
        onPress={handleAccessRequest}
        addedStyles={{ backgroundColor: colors.green }}
        addedTextStyles={{ fontSize: fontSize.large  }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: distance.large
  },
  panel: {
    padding: distance.large,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.gray.lightest,
    borderRadius: distance.small
  },
  mainText: {
    fontSize: fontSize.large,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  smallText: {
    color: colors.gray.darker,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  textSectionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
});
