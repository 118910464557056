import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigate } from 'react-router-dom';

import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import en from 'javascript-time-ago/locale/en';

import { textDisplay } from './textDisplay';
import styleGuide from '../../../assets/styleGuide';
const { distance, colors } = styleGuide;
TimeAgo.addLocale(en);
TimeAgo.addLocale(es);

export default function NotificationItem({ notification }) {
  const navigate = useNavigate()
  const { event, seen, created_at, reason } = notification;
  const timeAgo = new TimeAgo('en-US');
  const canAccessEvent = reason !== 'notif_event_access_request_denied' && reason !== 'notif_event_access_revoked'


  const pressHandler = () => {
    let path = `/events/${event.id}`;
    if (reason === "notif_event_info_changes") path = `/events/${event.id}/details`;

    navigate(path);
  };

  const backgroundColor = seen ? colors.gray.lighter : colors.white;
  const timeColor = seen ? colors.gray.darker : colors.gray.light;

  const notificationBody = () => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text style={{ maxWidth: '90%' }}>
        {
          textDisplay(notification).map(({ bold, text }, idx) => {
            return (
              <Text
                key={idx}
                style={{ fontWeight: bold ? 'bold' : 'normal' }}
              >
                {`${text} `}
              </Text>
            )
          })
        }
      </Text>
    </View>
  )

  const timeAgoText = () => (
    <Text style={{ ...styles.timeAgoText, color: timeColor }}>{timeAgo.format(new Date(created_at), 'mini')}</Text>
  )

  return (
    <View style={{ ...styles.viewWrapper, backgroundColor }}>
      {
        canAccessEvent
          ? (
            <TouchableOpacity style={styles.notificationWrapper} onPress={pressHandler} activeOpacity={0.6}>
              {notificationBody()}
              {timeAgoText()}
            </TouchableOpacity>
          )
          : <View style={styles.notificationWrapper}>{notificationBody()}{timeAgoText()}</View>
      }
    </View>
  )
}

const styles = StyleSheet.create({
  viewWrapper: {
    padding: 10,
    borderBottomColor: colors.gray.light,
    borderBottomWidth: 1,
  },
  notificationWrapper: {
    padding: styleGuide.distance.small,
  },
  iconWrapper: {
    height: 40,
    width: 40,
    backgroundColor: colors.gray.lighter,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: distance.large
  },
  timeAgoText: {
    position: 'absolute',
    right: distance.small,
    bottom: 0,
  }
});
