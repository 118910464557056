import React from 'react';
import Icon from "../assets/icons/base";

export const rsvpIcon = (text) => {
  let iconName;
  if (text === 'pending') iconName = 'rsvp_pending';
  if (text === 'attending') iconName = 'rsvp_accept';
  if (text === 'rejected') iconName = 'rsvp_reject';

  if (iconName) return <Icon fileName={iconName} />

  return null;
}
