import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ActivityIndicator, View } from 'react-native-web';

import SmoothScroll from "smooth-scroll";
import { createConsumer } from '@rails/actioncable'

import ActionCableChannelManager from './utils/sockets';
import MobileRoute from './utils/mobile_route';

// Desktop only pages
import { Homepage } from "./pages/homepage";
import { ContactPage } from "./pages/contact";
import { PrivacyPage } from "./pages/privacy";

// Mobile Screens
// Logged in screens
import Profile from "./screens/profile";
import EventShow from './screens/events/Show';
import EventDetails from './screens/events/Details';
import EventInvites from './screens/events/Invites';
import EventItems from './screens/events/Items';
import EventAnnouncements from './screens/events/Announcements';
import EventIndex from './screens/events/Index';
import Notifications from './screens/Notifications';
import Settings from './screens/Settings';
import NotFound404 from './screens/404NotFound';
import DownloadApp from './screens/DownloadApp';
import Unauthorized401 from './screens/401Unauthorized';
import VerifyEmail from './screens/VerifyEmail';

// Logged out screens
import Login from "./screens/LoginEmail";
import Signup from "./screens/SignupEmail";
import ForgotPassword from './screens/ForgotPassword';
import ResetPassword from './screens/PasswordReset';
import StorePathAndRedirect from './screens/StorePathAndRedirect';

import { SET_CURRENT_USER } from './reducers/Auth';
import { getStoredAuth } from './utils/storage';
import { ADD_NOTIFICATION } from './reducers/NotificationsReducer';

import { store } from './store/store';
import "./App.css";
import "./assets/css/style.css";
import "./assets/css/bootstrap.css";
import "./assets/css/nivo-lightbox/default.css";
import "./assets/css/nivo-lightbox/nivo-lightbox.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export const appStoreUrl = "https://apps.apple.com/us/app/gathr-up/id6445887816";
export const playStoreUrl = "https://play.google.com/store/apps/details?id=com.igo.igo";

const AppProvider = () => {
  return (
    <Provider store={store}><App /></Provider>
  )
}

const App = () => {
  const dispatch = useDispatch();
  const authState = useSelector(state => state.auth);
  const consumer = new ActionCableChannelManager();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Rehydrate the current user
    if (!authState.currentUser?.id) {
      const authInfo = getStoredAuth();
      if (authInfo) {
        const { id, name, token, email, emailVerified } = authInfo;
        dispatch({ type: SET_CURRENT_USER, value: { id, name, token, email, emailVerified } });
      }
    }

    setLoading(false);
  }, [authState.currentUser?.id, dispatch]);

  useEffect(() => {
    if (authState.currentUser?.id) {
      (async () => {
        const endpoint = process.env.REACT_APP_API_WS_ENDPOINT;
        const wsEndpoint = `${endpoint}/cable?token=${authState.currentUser.token}`;

        consumer.actionCable = createConsumer(wsEndpoint);
        await subscribeToNotificationsAndFutureEvents(consumer);
      })()
    }
  })

  const subscribeToNotificationsAndFutureEvents = async (consumer) => {
    const receiveNotification = data => dispatch({ type: ADD_NOTIFICATION, value: data });

    consumer.subscribe(
      { channel: 'NotificationChannel', user_id: authState.currentUser.id },
      { received: receiveNotification }
    );
  }

  if (loading) {
    return (
      <View style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ActivityIndicator size="large" />
      </View>
    )
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/contact" element={<ContactPage />} />
        <Route exact path="/privacy-policy" element={<PrivacyPage />} />
        <Route exact path="/download" element={<MobileRoute Element={<DownloadApp />} />} />

        {
          authState.currentUser?.id
            ? <>
                <Route exact path="/profile"         element={<MobileRoute Element={<Profile />} />} />
                <Route exact path="/profile/:id"     element={<MobileRoute Element={<Profile />} />} />
                <Route exact path="/events/upcoming" element={<MobileRoute Element={<EventIndex indexType="upcoming" />} />} />
                <Route exact path="/notifications"   element={<MobileRoute Element={<Notifications />} />} />
                <Route exact path="/settings"        element={<MobileRoute Element={<Settings />} />} />

                {
                  authState.currentUser?.emailVerified ? (
                    <>
                      <Route exact path="/events/:id"               element={<MobileRoute Element={<EventShow />} />} />
                      <Route exact path="/events/:id/details"       element={<MobileRoute Element={<EventDetails />} />} />
                      <Route exact path="/events/:id/invites"       element={<MobileRoute Element={<EventInvites />} />} />
                      <Route exact path="/events/:id/items"         element={<MobileRoute Element={<EventItems />} />} />
                      <Route exact path="/events/:id/announcements" element={<MobileRoute Element={<EventAnnouncements />} />} />
                      <Route exact path="/resource-not-found"       element={<MobileRoute Element={<NotFound404 />} />} />
                      <Route exact path="/unauthorized"             element={<MobileRoute Element={<Unauthorized401 />} />} />
                      <Route path="*"                               element={<MobileRoute Element={<NotFound404 />} />} />
                    </>
                  ) : (
                    <>
                      <Route exact path="*" element={<MobileRoute Element={<VerifyEmail />} />} />
                    </>
                  )
                }

                <Route exact path="/resource-not-found" element={<MobileRoute Element={<NotFound404 />} />} />
                <Route exact path="/unauthorized"       element={<MobileRoute Element={<Unauthorized401 />} />} />
                <Route path="*"                         element={<MobileRoute Element={<NotFound404 />} />} />
              </>
            : <>
                <Route exact path="/login"           element={<MobileRoute Element={<Login                />} />} />
                <Route exact path="/signup"          element={<MobileRoute Element={<Signup               />} />} />
                <Route exact path="/forgot-password" element={<MobileRoute Element={<ForgotPassword       />} />} />
                <Route exact path="/password-reset"  element={<MobileRoute Element={<ResetPassword        />} />} />
                <Route path="*"                      element={<MobileRoute Element={<StorePathAndRedirect />} />} />
              </>
        }
      </Routes>
    </Router>
  );
};

export default AppProvider;
