import React from 'react';

import BaseScreen from '../components/screen_specific/BaseScreen';
import Body from '../components/screen_specific/settings/Body';


export default function Settings() {
  return (
    <BaseScreen component={Body} />
  );
}
