import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { useNavigate } from 'react-router-dom';

import ListItem from './ListItem';
import Empty from '../../Empty';

import { getEvents } from '../../../utils/api/events';
import languageSpecificText from '../../../helpers/languages';
import styleGuide from '../../../assets/styleGuide';

export default function Upcoming({ type }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      const res = await getEvents(type);
      setLoading(false)
      setEvents(res.events);
    };

    fetchEvents()
  }, [type]);

  let emptyText1;
  if (type === 'upcoming') emptyText1 = languageSpecificText('events', 'noUpcomingEvents');

  // The two below lines are in case we want to add other types of event lists (eg. Hosted, Past)
  // if (type === 'past') emptyText1 = languageSpecificText('events', 'noPastEvents');
  // if (type === 'hosted') emptyText1 = languageSpecificText('events', 'noHostedEvents');

  return (
    <View>
      {loading && <ActivityIndicator size="large" style={{ marginTop: styleGuide.distance.large }} />}
      {
        !loading && events.length === 0
          ? <Empty
            texts={[emptyText1, languageSpecificText('events', 'createEvent')]}
            iconFile="shrug"
          />
          : events.map((event, idx) => {
            return <ListItem
              key={idx + 1}
              event={event}
              visitEvent={() => navigate(`/events/${event.id}`)}
            />
          })
      }
    </View>
  )
}
