import React from 'react';
import { StyleSheet, TouchableHighlight, View, Text } from 'react-native-web';

import styleGuide from '../../../assets/styleGuide';
import languageSpecificText from '../../../helpers/languages';
const { colors, distance } = styleGuide;

export default function LineItem({
  children,
  idx,
  showRemoveConfirm,
  removeAction,
  removeConfirmText = languageSpecificText('general', 'remove'),
  addedStyles = {}
}) {
  let removeConfirmation = null;
  if (showRemoveConfirm) {
    removeConfirmation = (
      <TouchableHighlight onPress={removeAction} style={styles.removeConfirm} underlayColor={colors.red.dark}>
        <Text style={styles.removeConfirmText}>{removeConfirmText}</Text>
      </TouchableHighlight>
    )
  }

  return (
    <View key={idx}>
      <View style={{ ...styles.li, ...addedStyles }}>
        {children}
      </View>
      {removeConfirmation}
    </View>
  )
}

const styles = StyleSheet.create({
  li: {
    padding: distance.medium,
    borderBottomColor: colors.gray.light,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
  },
  removeConfirm: {
    backgroundColor: colors.red.light,
    height: '100%',
    width: 100,
    flex: 3,
    position: 'absolute',
    right: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  removeConfirmText: {
    color: colors.white,
    textAlign: 'center',
  }
});
