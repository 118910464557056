import React from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Footer } from "../components/footer/Desktop";
import { DesktopNav } from '../components/navbar/Desktop';
import { isMobile } from '../helpers/is_mobile';
import languageSpecificText from '../helpers/languages';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const MobileRoute = ({ Element }) => {
  if (isMobile()) return Element;

  return (
    <div style={styles.pageWrap}>
      <DesktopNav />
      <div style={styles.textWrap}>
        <h1>{languageSpecificText('mobileOnly', 'title')}</h1>
        <p>{languageSpecificText('mobileOnly', 'explanation')}</p>
      </div>
      <Footer />
    </div>
  )
}

const styles = {
  pageWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textWrap: {
    maxWidth: '70%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
  }
}

export default withRouter(MobileRoute);
