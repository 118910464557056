import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, TouchableOpacity, ActivityIndicator } from 'react-native';

import LineItem from './LineItem';
import Empty from '../../Empty';

import styleGuide from '../../../assets/styleGuide';
import { getEventItems, updateEventItem } from '../../../utils/api/events';
import { dateIsFuture } from '../../../helpers/date';
import languageSpecificText from '../../../helpers/languages';
import { useDispatch } from 'react-redux';
import { SET_FLASH } from '../../../reducers/FlashReducer';

export default function AttendeeItemsRegistry() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: eventId } = useParams();

  const [event, setEvent] = useState(null);
  const [items, setItems] = useState(null);
  const [currUserOwns, setCurrUserOwns] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await getEventItems(eventId);
      if (res.errors) {
        if (res.errors[0] === 'eventNotFound') {
          navigate('/resource-not-found')
          return null;
        } else if (res.errors[0] === 'unauthorized') {
          navigate('/unauthorized')
          return null;
        }
      }

      setItems(res.items);
      setEvent(res.event);
      if (res.currentUserOwnsEvent) setCurrUserOwns(true);
    })()
  }, [eventId, navigate])

  const claimOrReleaseItem = async (type, itemId) => {
    const res = await updateEventItem(eventId, itemId, type);
    if (res.success) {
      const newItems = [...items];
      newItems.forEach(item => {
        if (item.id === parseInt(itemId)) {
          if (type === "claim") item.currentUserOwns = true;
          if (type === "release") {
            item.currentUserOwns = false;
            item.owner = null;
          }
        }
      })

      setItems(newItems);
    } else if (res.errors) {
      dispatch({ type: SET_FLASH, messages: res.errors, isSuccess: false });
    }
  }

  const claimReleaseOrImg = (item) => {
    const { itemAction, releaseItem, claimItem, userImg, itemActionWrap } = styles;

    if (!dateIsFuture(event.dateTime) || currUserOwns) {
      if (!item.owner) return null;
      return (
        <View style={itemActionWrap}>
          <Image style={userImg} source={{ uri: item.owner.imgUrl }} />
        </View>
      );
    }

    let innerComponent;
    let parentStyles = { ...itemActionWrap };

    if (item.currentUserOwns) {
      parentStyles = { ...parentStyles, ...releaseItem };
      innerComponent = (
        <TouchableOpacity onPress={() => claimOrReleaseItem('release', item.id)}>
          <Text style={itemAction}>{languageSpecificText('events', 'release')}</Text>
        </TouchableOpacity>
      );
    } else if (!item.owner) {
      parentStyles = { ...parentStyles, ...claimItem };
      innerComponent = (
        <TouchableOpacity onPress={() => claimOrReleaseItem('claim', item.id)}>
          <Text style={itemAction}>{languageSpecificText('events', 'claim')}</Text>
        </TouchableOpacity>
      );
    } else if (item.owner) {
      innerComponent = <Image
        style={userImg}
        source={{ uri: item.owner.imgUrl }}
      />;
    } else {
      innerComponent = "Uh oh..."
    }

    return <View style={parentStyles}>{innerComponent}</View>
  }

  if (!event || !items) {
    return <ActivityIndicator size="large" style={{ marginTop: styleGuide.distance.large }} />;
  }

  if (items.length === 0) {
    return <Empty goBack texts={[languageSpecificText('items', 'noItems')]} iconFile="empty_tray" />
  }

  return (
    <View>
      {
        items.map((item, idx) => {
          return (
            <LineItem key={idx}>
              <Text style={styles.wrap}>{item.description}</Text>
              {claimReleaseOrImg(item)}
            </LineItem>
          )
        })
      }
    </View>
  )
}

const styles = StyleSheet.create({
  liWrapper: {
    padding: styleGuide.distance.medium,
    borderBottomColor: styleGuide.colors.gray.light,
    borderBottomWidth: 1,
  },
  iconWrapper: {
    height: 50,
    width: 50,
    backgroundColor: styleGuide.colors.gray.lighter,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: styleGuide.distance.large
  },
  wrap: {
    flex: 1,
    flexWrap: 'wrap'
  },
  userImg: {
    width: 30,
    height: 30,
    borderRadius: 20,
  },
  releaseItem: {
    backgroundColor: styleGuide.colors.gray.light,
  },
  claimItem: {
    backgroundColor: styleGuide.colors.green,
  },
  itemAction: {
    width: '100%',
    color: styleGuide.colors.white,
    fontSize: styleGuide.fontSize.small,
    textAlign: 'center',
    textAlignVertical: 'center'
  },
  itemActionWrap: {
    height: 30,
    width: 80,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  }
});
