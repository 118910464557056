import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../assets/icons/base';
import LineItem from '../event_show/LineItem';

import { getHourAndMinute } from '../../../helpers/date';
import { rsvpIcon } from '../../../helpers/rsvp_icon';
import { UserImage } from '../../../helpers/usr_img';

import styleGuide from '../../../assets/styleGuide';
import languageSpecificText from '../../../helpers/languages';
const { colors, fontSize, distance } = styleGuide;

export default function ListItem({ event, idx, visitEvent }) {
  const navigate = useNavigate();

  const eventDate = new Date(event.dateTime);
  const month = eventDate.toLocaleString('default', { month: 'long' });
  const date = eventDate.getDate();
  const hourAndMin = getHourAndMinute(eventDate);
  const profileLink = event.currUserIsCreatorOrHost ? '/profile' : `/profile/${event.creator.id}`;
  const profileLinkText = event.currUserIsCreatorOrHost ? languageSpecificText('events', 'me') : `${event.creator.name}`;

  return (
    <TouchableOpacity key={idx} onPress={visitEvent}>
      <LineItem idx={idx} addedStyles={{ justifyContent: 'space-between' }}>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <UserImage name={event.creator?.name} image={event.creator?.imgUrl} />

          <View>
            <Text style={{ fontSize: fontSize.subTitle }}>{event.title}</Text>
            <Text style={{ color: colors.gray.darker, fontSize: fontSize.small }}>
              {`${month}, ${date} @ ${hourAndMin}`}
            </Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={{ color: colors.gray.darker }}>
                {languageSpecificText('events', 'hostedBy')}
              </Text>

              <Text
                onPress={() => navigate(profileLink)}
                style={{ marginLeft: distance.small, color: colors.blue.light, textDecorationLine: 'underline' }}
              >
                {profileLinkText}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {rsvpIcon(event.currUserRsvp)}
          <Icon fileName="chevron_right" onPress={visitEvent} addedStyles={{ marginLeft: distance.small }} />
        </View>
      </LineItem>
    </TouchableOpacity>
  )
}
