import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native-web';

import Icon from '../../../assets/icons/base';
import LineItem from './LineItem';

import styleGuide from '../../../assets/styleGuide';
import { getEventRsvps } from '../../../utils/api/events';
import Empty from '../../Empty';
import languageSpecificText from '../../../helpers/languages';
import { UserImage } from '../../../helpers/usr_img';

export default function AttendeeRSVPList() {
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const [rsvps, setRsvps] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await getEventRsvps(eventId);

      if (res.errors) {
        if (res.errors[0] === 'eventNotFound') {
          navigate('/resource-not-found')
          return null;
        } else if (res.errors[0] === 'unauthorized') {
          navigate('/unauthorized')
          return null;
        }
      }

      setRsvps(res.rsvps);
    })()
  }, [eventId, navigate])

  if (rsvps === null) {
    return <ActivityIndicator size="large" style={{ marginTop: styleGuide.distance.large }} />;
  }

  if (rsvps.length === 0) {
    return <Empty texts={[languageSpecificText('events', 'noRsvps')]} iconFile="shrug" goBack />
  }

  return (
    <View style={styles.container}>
      {
        rsvps.map((rsvp, idx) => {
          let iconFileName;
          switch (rsvp.rsvp) {
            case "attending":
              iconFileName = "circle_green_check";
              break;
            case "rejected":
              iconFileName = "circle_red_x";
              break;
            default:
              iconFileName = "circle_teal_question";
          }

          return (
            <LineItem key={idx}>
              <View style={styles.lineWrap}>

                <View style={styles.imageAndName}>
                  <View style={{ marginRight: styleGuide.distance.large }}>
                    <UserImage name={rsvp.name} image={rsvp.imgUrl} size="large" />
                  </View>

                  <Text ellipsizeMode="tail" style={styles.name}>{rsvp.name}</Text>
                </View>

                <Icon fileName={iconFileName}/>
              </View>
            </LineItem>
          )
        })
      }
    </View>
  )
}

const styles = StyleSheet.create({
  lineWrap: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imageAndName: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  userImg: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: styleGuide.distance.large,
  },
});
