import React from 'react';
import { StyleSheet, View, ScrollView } from 'react-native-web';

import NavBar from '../navbar/Mobile';
import Footer from '../footer/Mobile';
import styleGuide from '../../assets/styleGuide';
import { useDispatch, useSelector } from 'react-redux';
import Flash from '../Flash';
import { CLEAR_FLASH } from '../../reducers/FlashReducer';

export default function BaseScreen({
  component: MainContent,
  componentProps = {},
  addPaddingTopToMainContainer = true,
  scrollView = true,
}) {
  const flashState = useSelector(state => state.flash);
  const dispatch = useDispatch();

  let mainContainerStyles = { ...styles.container };
  if (!addPaddingTopToMainContainer) {
    mainContainerStyles.paddingTop = null;
  }

  const ViewType = scrollView ? ScrollView : View;

  return (
    <View style={styles.wrapper}>
      <View style={{ flex: 1, zIndex: 10 }}>
        <NavBar />
      </View>

      {
        flashState.messages && (
          <Flash
            messages={flashState.messages}
            success={flashState.isSuccess}
            onExit={() => dispatch({ type: CLEAR_FLASH })}
            addedStyles={{ top: 100, width: '95%', display: 'flex', alignSelf:'center' }}
          />
        )
      }

      <View style={mainContainerStyles}>
        <ViewType showsVerticalScrollIndicator={false}>
          <MainContent {...componentProps} />
        </ViewType>
      </View>

      <View style={{ flex: 1 }}>
        <Footer />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    height: '100%'
  },
  container: {
    flex: 8,
    backgroundColor: styleGuide.colors.white,
    paddingTop: styleGuide.distance.large,
  },
});
