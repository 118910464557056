import React, { useEffect } from 'react';

import NotificationList from './NotificationList';

import { readNotifications } from '../../../utils/api/users';
import { useSelector } from 'react-redux';

export default function Body() {
  const notificationState = useSelector(state => state.notifications);

  useEffect(() => {
    return () => {
      (async () => {
        const notifIdsToMarkAsSeen = notificationState
          .filter(notif => !notif.seen)
          .map(notif => notif.id);

        if (notifIdsToMarkAsSeen.length > 0) {
          await readNotifications(notifIdsToMarkAsSeen);
        }
      })()
    }
  }, [notificationState])

  return (
    <NotificationList notificationState={notificationState} />
  );
}
