import React from 'react';

import BaseScreen from '../../components/screen_specific/BaseScreen';
import EventShowScreen from '../../components/screen_specific/event_show/Show';

export default function EventShow() {
  return (
    <BaseScreen
      component={EventShowScreen}
      addPaddingTopToMainContainer={false}
      scrollView={false}
    />
  );
}
