import React from 'react';

import BaseScreen from '../components/screen_specific/BaseScreen';
import Empty from '../components/Empty';
import languageSpecificText from '../helpers/languages';

export default function Home() {
  return (
    <BaseScreen
      component={Empty}
      componentProps={{ texts: [languageSpecificText('empty404')], iconFile: "magnifying_empty" }}
    />
  );
}
