import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { STORE_PATH } from '../reducers/Auth';

export default function StorePathAndRedirect() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: STORE_PATH, value: location.pathname });
    navigate('/login');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
