import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { makeAuthRequest } from "./api/auth";
import { getStoredAwsCred, storeAwsCreds } from "./storage";

const REGION = "us-east-1";

export const getAwsImgUrl = async (key) => {
  const s3Client = await getUpdatedS3Client();
  try {
    const bucketParams = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: key,
    };
    const getObjCommand = new GetObjectCommand(bucketParams);
    return await getSignedUrl(s3Client, getObjCommand, { expiresIn: 30 });
  } catch (err) {
    console.log(err)
    global.gathrUp.Bugsnag.notify(`Error getting presigned url for: ${key}`)
  }
};

const getNewAuthCreds = async () => {
  return await makeAuthRequest('/aws_creds', 'GET');
};

const getAndSetAwsCreds = async () => {
  const res = await getNewAuthCreds();
  await storeAwsCreds(res);
  return res;
}

const getUpdatedS3Client = async () => {
  let storedCreds = await getStoredAwsCred();
  if (!storedCreds?.expires || new Date(storedCreds.expires) < new Date()) {
    storedCreds = await getAndSetAwsCreds();
  }

  const { accessKeyId, secretAccessKey, sessionToken } = storedCreds;
  const creds = { accessKeyId, secretAccessKey, sessionToken };
  return new S3Client({
    apiVersion: '2006-03-01',
    region: REGION,
    credentials: creds
  });
};
