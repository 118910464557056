import { appStoreUrl, playStoreUrl } from "../../App"

export const Footer = () => {
  return (
    <div id='footer'>
      <div className='container text-left'>
        <div className="row">
          <div className="col-md-4">
            <div className='footer-brand'>
              GathrUp
            </div>
          </div>
          <div className="col-md-8 text-right">
            <div className="footer-download-links">
              <div className="footer-copyright">
                <a href="/privacy-policy">Privacy Policy</a>
                <p>&copy; 2022 GathrUp. All Rights Reserved.</p>
              </div>
              <div className="footer-download-icons">
                <a href={appStoreUrl} className="footer-download-link"><img src="img/app-store-icon.png" className="footer-download-icon" alt="" /></a>
                <a href={playStoreUrl} className="footer-download-link"><img src="img/google-play-icon.png" className="footer-download-icon" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
