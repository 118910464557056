import { configureStore } from '@reduxjs/toolkit'

import AuthReducer from '../reducers/Auth';
import EventsReducer from '../reducers/Events';
import HostAttendeesReducer from '../reducers/HostAttendeesManage';
import NotificationsReducer from '../reducers/NotificationsReducer';
import FlashReducer from '../reducers/FlashReducer';

const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: { warnAfter: 128 },
    serializableCheck: { warnAfter: 128 },
  }),
  reducer: {
    auth: AuthReducer,
    notifications: NotificationsReducer,
    hostAttendees: HostAttendeesReducer,
    events: EventsReducer,
    flash: FlashReducer,
  }
});

export { store };
