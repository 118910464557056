import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import en from 'javascript-time-ago/locale/en';
import { getMonthAndDate } from "../../../helpers/date";
import languageSpecificText from "../../../helpers/languages";
TimeAgo.addLocale(en);
TimeAgo.addLocale(es);

export const textDisplay = (notification) => {
  switch(notification.reason) {
    case 'notif_event_invite':
      return notif_event_invite(notification);
    case 'notif_event_cancellation':
      return notif_event_cancellation(notification);
    case 'notif_event_info_changes':
      return notif_event_info_changes(notification);
    case 'notif_event_items_added':
      return notif_event_items_added(notification);
    case 'notif_event_reminders':
      return notif_event_reminders(notification);
    case 'notif_event_respond':
      return notif_event_respond(notification);
    case 'notif_event_announcement':
      return notif_event_announcement(notification);
    case 'notif_event_access_request':
      return notif_event_access_request(notification);
    case 'notif_event_access_request_granted':
      return notif_event_access_request_granted(notification);
    case 'notif_event_access_request_denied':
      return notif_event_access_request_denied(notification);
    case 'notif_event_access_revoked':
      return notif_event_access_revoked(notification);
    default:
      return ["Notification reason unhandled"];
  }
}

const baseText = (notification) => {
  const { event, reason } = notification;
  const textToDisplay = [];
  textToDisplay.push({ bold: true, text: event.owner });
  textToDisplay.push({ bold: false, text: languageSpecificText('notifications', 'item', reason) });
  textToDisplay.push({ bold: true, text: event.title });
  return textToDisplay;
}

const notif_event_invite = (notification) => {
  const { event } = notification;
  const res = baseText(notification);
  res.push({ bold: false, text: languageSpecificText('notifications', 'item', 'dateConjecture') });
  res.push({ bold: true, text: getMonthAndDate(event.dateTime) });
  return res;
}

const notif_event_cancellation = notification => {
  return baseText(notification);
}

const notif_event_info_changes = notification => {
  const { attrs_changed } = notification;
  const res = baseText(notification);

  res.push({ bold: false, text: '-' });
  res.push({ bold: false, text: languageSpecificText('notifications', 'item', 'updatedAttrs', 'changesIn') });
  res.push({ text: '\n' });
  for (let i = 0; i < attrs_changed.length; i++) {
    const attr = attrs_changed[i];
    res.push({ bold: false, text: `• ${languageSpecificText('notifications', 'item', 'updatedAttrs', attr)}` });
    if (i < attrs_changed.length - 1) {
      res.push({ text: '\n' });
    }
  }

  return res;
}

const notif_event_items_added = notification => {
  const res = baseText(notification);
  res.push({ bold: false, text: '-' });
  res.push({ bold: false, text: languageSpecificText('notifications', 'item', 'claimItems') });
  return res;
}

const notif_event_reminders = notification => {
  const timeAgo = new TimeAgo(global.gathrUp.locale || 'en-US');
  const { event } = notification;

  if (new Date() > new Date(event.dateTime)) {
    return [
      { bold: false, text: languageSpecificText('notifications', 'item', 'pastEventReminder') },
      { bold: true, text: event.title },
    ]
  }

  return [
    { bold: false, text: languageSpecificText('notifications', 'item', 'reminder') },
    { bold: true, text: event.title },
    { bold: false, text: languageSpecificText('notifications', 'item', 'startsIn') },
    { bold: true, text: timeAgo.format(new Date(event.dateTime))}
  ];
}

const notif_event_respond = notification => {
  const timeAgo = new TimeAgo(global.gathrUp.locale || 'en-US');
  const { event } = notification;

  if (new Date() > new Date(event.dateTime)) {
    return [
      { bold: false, text: languageSpecificText('notifications', 'item', 'pastEventRespond') },
      { bold: true, text: event.title },
    ]
  }

  return [
    { bold: false, text: languageSpecificText('notifications', 'item', 'dontForget') },
    { bold: true, text: event.title },
    { bold: false, text: languageSpecificText('notifications', 'item', 'startsIn') },
    { bold: true, text: timeAgo.format(new Date(event.dateTime)) }
  ];
}

const notif_event_announcement = notification => {
  const { event, reason } = notification;

  const textToDisplay = [];
  textToDisplay.push({ bold: false, text: languageSpecificText('notifications', 'item', reason) });
  textToDisplay.push({ bold: true, text: event.title });

  return textToDisplay;
}

const notif_event_access_request = notification => {
  const { event } = notification;
  const textToDisplay = [];
  textToDisplay.push({ bold: false, text: languageSpecificText('notifications', 'item', 'accessRequest') });
  textToDisplay.push({ bold: true, text: event.title });
  return textToDisplay;
}

const notif_event_access_request_granted = notification => {
  const { event } = notification;
  const textToDisplay = [];
  textToDisplay.push({ bold: false, text: languageSpecificText('notifications', 'item', 'accessGranted') });
  textToDisplay.push({ bold: true, text: event.title });
  return textToDisplay;
}

const notif_event_access_request_denied = notification => {
  const { event } = notification;
  const textToDisplay = [];
  textToDisplay.push({ bold: false, text: languageSpecificText('notifications', 'item', 'accessDenied') });
  textToDisplay.push({ bold: true, text: event.title });
  return textToDisplay;
}

const notif_event_access_revoked = notification => {
  const { event } = notification;
  const textToDisplay = [];
  textToDisplay.push({ bold: false, text: languageSpecificText('notifications', 'item', 'accessRevoked') });
  textToDisplay.push({ bold: true, text: event.title });
  return textToDisplay;
}
