import textCopy from "../assets/textCopy";

export default function languageSpecificText(...keys) {
  // Figure out how to get device language here
  let language = global.gathrUp.language;
  if (!language) language = 'en';
  if (!(language in textCopy)) language = 'en';

  let txt = textCopy[language];
  for (let key of keys) {
    txt = txt[key]
  }

  return txt;
}
