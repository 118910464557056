import React from 'react';

import BaseScreen from '../components/screen_specific/BaseScreen';
import Body from '../components/screen_specific/profile/Body';

export default function Profile() {
  return (
    <BaseScreen component={Body} />
  );
}
