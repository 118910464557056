import React, { useEffect, useState } from 'react';
import { View, Text, ActivityIndicator, FlatList } from 'react-native';
import { useParams } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import en from 'javascript-time-ago/locale/en';

import LineItem from './LineItem';
import Empty from '../../Empty';

import { getEventAnnouncements } from '../../../utils/api/events';
import languageSpecificText from '../../../helpers/languages';
import styleGuide from '../../../assets/styleGuide';
TimeAgo.addLocale(en);
TimeAgo.addLocale(es);
const { colors, distance } = styleGuide;

export default function HostAnnouncements() {
  const { id: eventId } = useParams();
  const [announcements, setAnnouncements] = useState(null);
  const timeAgo = new TimeAgo('en-US');

  useEffect(() => {
    (async () => {
      const res = await getEventAnnouncements(eventId);
      setAnnouncements(res.announcements);
    })()
  }, [eventId]);

  if (!announcements) {
    return <ActivityIndicator size="large" style={{ marginTop: distance.large }} />;
  }

  const renderAnnouncement = (obj) => {
    const announcement = obj.item;

    return (
      <LineItem>
        <View style={{ width: '100%' }}>
          <Text style={{ width: '80%' }}>{announcement.body}</Text>
          <Text style={{ position: 'absolute', right: 0, bottom: 0, color: colors.gray.darker }}>
            {timeAgo.format(new Date(announcement.created_at), 'mini')}
          </Text>
        </View>
      </LineItem>
    )
  }

  return (
    <View>
      <FlatList
        keyboardShouldPersistTaps="handled"
        data={announcements}
        renderItem={renderAnnouncement}
        keyExtractor={(obj) => obj.id}
        ListEmptyComponent={<Empty texts={[languageSpecificText('announcements', 'attendeeEmpty')]} iconFile="shrug" />}
      />

    </View>
  )
}
