import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M3 5.25a.75.75 0 0 0 0 1.5v-1.5Zm18 1.5a.75.75 0 0 0 0-1.5v1.5ZM19 6h.75a.75.75 0 0 0-.75-.75V6ZM5 20h-.75H5ZM7.25 6a.75.75 0 0 0 1.5 0h-1.5ZM10 2v-.75V2Zm4 0v-.75V2Zm1.25 4a.75.75 0 0 0 1.5 0h-1.5ZM3 6.75h2v-1.5H3v1.5Zm2 0h16v-1.5H5v1.5ZM18.25 6v14h1.5V6h-1.5Zm0 14c0 .331-.132.65-.366.884l1.06 1.06A2.75 2.75 0 0 0 19.75 20h-1.5Zm-.366.884a1.25 1.25 0 0 1-.884.366v1.5c.73 0 1.429-.29 1.945-.805l-1.061-1.061ZM17 21.25H7v1.5h10v-1.5Zm-10 0a1.25 1.25 0 0 1-.884-.366l-1.06 1.06A2.75 2.75 0 0 0 7 22.75v-1.5Zm-.884-.366A1.25 1.25 0 0 1 5.75 20h-1.5c0 .73.29 1.429.805 1.945l1.061-1.061ZM5.75 20V6h-1.5v14h1.5ZM5 6.75h14v-1.5H5v1.5ZM8.75 6V4h-1.5v2h1.5Zm0-2c0-.332.132-.65.366-.884l-1.06-1.06A2.75 2.75 0 0 0 7.25 4h1.5Zm.366-.884A1.25 1.25 0 0 1 10 2.75v-1.5c-.73 0-1.429.29-1.945.805l1.061 1.061ZM10 2.75h4v-1.5h-4v1.5Zm4 0c.332 0 .65.132.884.366l1.06-1.06A2.75 2.75 0 0 0 14 1.25v1.5Zm.884.366c.234.235.366.552.366.884h1.5c0-.73-.29-1.429-.806-1.945l-1.06 1.061ZM15.25 4v2h1.5V4h-1.5Z"
      fill="#1A1A1A"
    />
  </Svg>
)

export default SvgComponent
