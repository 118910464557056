export default class ActionCableChannelManager {
  constructor(actionCable) {
    this.actionCable = actionCable;
    this.channels = {};
  }

  subscribe(data, callbacks) {
    const channel = this.actionCable.subscriptions.create(data, {
      received: (x) => {
        if (callbacks.received) callbacks.received(x)
      },
      initialized: () => {
        if (callbacks.initialized) callbacks.initialized()
      },
      connected: () => {
        if (callbacks.connected) callbacks.connected()
      },
      disconnected: () => {
        if (callbacks.disconnected) callbacks.disconnected()
      }
    })

    this.channels[data] = channel;
  }

  unsubscribe(channel) {
    if (channel) {
      this.actionCable.subscriptions.remove(channel);
      delete this.channels[channel];
    }
  }

  unsubscribeAll() {
    Object.keys(this.channels).forEach(channel => {
      if (channel) this.unsubscribe(channel);
    })

    this.channels = {};
  }

  send(type, payload) {
    try {
      this.channel.perform(type, payload)
    } catch (e) {
      throw new Error('ActionCableChannel#send - ERROR: ' + e)
    }
  }
}
