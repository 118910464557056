import React from 'react';

import BaseScreen from '../../components/screen_specific/BaseScreen';
import AttendeeRSVPList from '../../components/screen_specific/event_show/Invites';

export default function InvitesScreen({ route }) {
  return (
    <BaseScreen
      component={AttendeeRSVPList}
      addPaddingTopToMainContainer={false}
    />
  );
}
