import React from 'react';
import { StyleSheet, Text, View } from 'react-native-web';

import { ProfileImg } from '../../../helpers/usr_img';
import styleGuide from '../../../assets/styleGuide';
import languageSpecificText from '../../../helpers/languages';
const { colors, distance } = styleGuide;

export default function ShowProfile({ user }) {
  return (
    <View style={styles.container}>
      <View style={{ alignSelf: 'center' , marginBottom: distance.xlarge }}>
        <ProfileImg name={user.name} image={user.imgUrl}/>
      </View>

      <View style={styles.line}>
        <Text style={styles.label}>{languageSpecificText('general', 'name').toUpperCase()}</Text>
        <Text style={styles.value}>{user.name}</Text>
      </View>
      <View style={styles.line}>
        <Text style={styles.label}>{languageSpecificText('general', 'email').toUpperCase()}</Text>
        <Text style={styles.value}>{user.email}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  line: {
    backgroundColor: colors.gray.light,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: distance.large,
    borderTopColor: colors.gray.darkest,
    borderTopWidth: 1,
  },
  label: {
    color: colors.gray.darkest,
    fontWeight: 'bold'
  },
  value: {
    fontWeight: 'bold'
  }
})
