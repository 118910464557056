import React, { useEffect } from 'react';
import { useState } from 'react';
import { Image, StyleSheet, View } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../assets/icons/base';
import Button from '../Button';

import styleGuide from '../../assets/styleGuide';
import languageSpecificText from '../../helpers/languages';
import { getNotifications, logout } from '../../utils/api/users';
import { SET_CURRENT_USER } from '../../reducers/Auth';
import { SET_NOTIFICATIONS } from '../../reducers/NotificationsReducer';
const { colors, distance } = styleGuide;

export default function MobileNavBar() {
  const LOGO_PATHNAMES = ['/', '/contact', '/privacy-policy'];

  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const notifState = useSelector(state => state.notifications);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (currentUser?.id) {
        const res = await getNotifications();
        dispatch({ type: SET_NOTIFICATIONS, value: res })
      }
    })()
  }, [currentUser?.id, dispatch])

  const handleLogout = async () => {
    await logout();
    dispatch({ type: SET_CURRENT_USER, value: null });
    navigate('/login');
  }

  let menuIcon = menuOpen ? 'close_x' : 'burger_menu';
  const hasUnreadNotifs = notifState.some(notif => !notif.seen);
  if (!menuOpen && (hasUnreadNotifs || !currentUser?.emailVerified)) {
    menuIcon = 'burger_menu_notification'
  }

  return (
    <View style={styles.container}>
      {
        LOGO_PATHNAMES.includes(location.pathname)
          ? <Link to="/"><Image source="img/logo-large.png" style={{ height: '50px', width: '50px' }} /></Link>
          : <Icon fileName="arrow_left" onPress={() => navigate(-1)}/>
      }

      <View>
        <Icon fileName={menuIcon} onPress={() => setMenuOpen(!menuOpen)} />
      </View>

      {
        menuOpen && (
          <View style={styles.menu}>
            <View style={styles.navLinkItem}>
              <Icon fileName="home" />
              <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/">
                {languageSpecificText('screenTitles', 'home')}
              </NavLink>
            </View>
            <View style={styles.navLinkItem}>
              <Icon fileName="address" />
              <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/contact">
                {languageSpecificText('screenTitles', 'contact')}
              </NavLink>
            </View>

            <View style={styles.lineSeparator} />
            {
              currentUser?.id
                ? (
                    <>
                      <View>
                        <View style={styles.navLinkItem}>
                          <Icon fileName="user" />
                          <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/profile">
                            {languageSpecificText('screenTitles', 'profile')}
                          </NavLink>
                        </View>

                        <View style={styles.navLinkItem}>
                          <Icon fileName={hasUnreadNotifs ? 'bell_notification' : 'bell'} />
                          <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/notifications">
                            {languageSpecificText('screenTitles', 'notifications')}
                          </NavLink>
                        </View>

                        <View style={styles.navLinkItem}>
                          <Icon fileName={currentUser.emailVerified ? 'cog' : 'cog_notification'} />
                          <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/settings">
                            {languageSpecificText('screenTitles', 'settings')}
                          </NavLink>
                        </View>

                        <View style={styles.navLinkItem}>
                          <Icon fileName="calendar_small" />
                          <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/events/upcoming">
                            {languageSpecificText('screenTitles', 'upcomingEvents')}
                          </NavLink>
                        </View>
                      </View>
                      <Button
                        async
                        onPress={handleLogout}
                        title={languageSpecificText('burgerNav', 'logout')}
                        addedStyles={{ backgroundColor: colors.red.light, marginTop: distance.xlarge }}
                      />
                    </>
                  )
                : (
                    <>
                      <View style={styles.navLinkItem}>
                        <Icon fileName="login" />
                        <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/login">
                          {languageSpecificText('screenTitles', 'login')}
                        </NavLink>
                      </View>
                      <View style={styles.navLinkItem}>
                        <Icon fileName="signup" />
                        <NavLink style={({ isActive }) => isActive ? styles.activeNavLink : styles.navLink} to="/signup">
                          {languageSpecificText('screenTitles', 'signup')}
                        </NavLink>
                      </View>
                    </>
                  )
            }
          </View>
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray.light,
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: distance.large,
  },
  menu: {
    position: 'absolute',
    top: '100%',
    right: 0,
    backgroundColor: colors.gray.light,
    padding: distance.large,
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    width: '70%',
    justifyContent: 'space-between'
  },
  navLinkItem: {
    marginBottom: distance.medium,
    flexDirection: 'row',
    alignItems: 'center'
  },
  navLink: {
    color: 'black',
    textDecorationLine: 'none',
    marginLeft: distance.small
  },
  activeNavLink: {
    color: 'black',
    textDecorationLine: 'none',
    fontWeight: 'bold',
    marginLeft: distance.small
  },
  lineSeparator: {
    borderBottomColor: colors.gray.darker,
    borderBottomWidth: 1,
    marginBottom: distance.large
  }
})
