export const SET_NOTIFICATIONS = 'set_notifications';
export const ADD_NOTIFICATION = 'add_notification';

const _defaultState = [];
export default function NotificationsReducer(state = _defaultState, action) {
  const dupState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return action.value;
    case ADD_NOTIFICATION:
      return [action.value, ...dupState];
    default:
      return state;
  }
}
