import React from 'react';

import BaseScreen from '../../components/screen_specific/BaseScreen';
import AttendeeDetails from '../../components/screen_specific/event_show/Details';

export default function EventDetailsScreen() {
  return (
    <BaseScreen
      component={AttendeeDetails}
      addPaddingTopToMainContainer={false}
    />
  );
}
