import React from 'react';
import { Image, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native-web';

import styleGuide from '../../assets/styleGuide';
import appStoreSrc from '../../assets/img/app-store-icon.png';
import googlePlayStoreSrc from '../../assets/img/google-play-icon.png';
import languageSpecificText from '../../helpers/languages';
import { isAndroid, isIphone } from '../../helpers/is_mobile';
import { appStoreUrl, playStoreUrl } from '../../App';

const { colors, distance } = styleGuide;

export default function MobileFooter() {
  const randomFooterPushNote = Math.ceil((Math.random() * 14));
  const footerPushText = languageSpecificText('footer', `push${randomFooterPushNote}`);
  return (
    <View style={styles.container}>
      <Text style={{ flex: 2, alignSelf: 'center', marginRight: distance.xsmall }}>{footerPushText}</Text>
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <StoreDownload />
      </View>
    </View>
  )
}

const StoreDownload = () => {
  let image;
  if (isAndroid()) image = <Image style={{...styles.storeImg, width: 120}} source={{ uri: googlePlayStoreSrc }} />;
  if (isIphone()) image = <Image style={{...styles.storeImg, width: 120}} source={{ uri: appStoreSrc }} />;

  if (image) return (
    <TouchableWithoutFeedback onPress={() => window.location.href = isIphone() ? appStoreUrl : playStoreUrl}>
      {image}
    </TouchableWithoutFeedback>
  )

  return <View style={styles.notSupported}><Text>{languageSpecificText('footer', 'availableOnStores')}</Text></View>;
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray.lighter,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: distance.small,
    height: '100%',
    borderTopColor: colors.gray.darker,
    borderTopHeight: 1,
  },
  storeImg: {
    height: 40,
    borderRadius: distance.xsmall
  },
  notSupported: {
    backgroundColor: colors.gray.darkest,
    borderRadius: distance.xsmall,
    padding: distance.xsmall
  }
});
