import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyleSheet, ScrollView, View, Text } from 'react-native-web';

import AuthScreenNav from '../components/navbar/AuthScreen';
import Button from '../components/Button';
import Flash from '../components/Flash';
import Icon from '../assets/icons/base';
import FieldSet from '../components/forms/FieldSet';

import { login } from '../utils/api/users';
import { SET_CURRENT_USER, STORE_PATH } from '../reducers/Auth';

import styleGuide from '../assets/styleGuide';
const { distance, colors, fontSize } = styleGuide;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedPath = useSelector(state => state.storedPath);

  const [formData, setFormData] = useState({ email: '', password: '' });
  const [backendErrors, setBackendErrors] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disableReason, setDisableReason] = useState('Fill out all fields');

  const onSubmit = async () => {
    const res = await login(formData);

    if (res.errors) {
      setBackendErrors(res.errors);
    } else {
      dispatch({ type: SET_CURRENT_USER, value: res });
      let path = '/';
      if (storedPath) {
        path = storedPath;
        dispatch({ STORE_PATH, value: null });
      }
      navigate(path)
    }
  };

  const handleChange = (field, val) => {
    const newFormData = { ...formData };
    newFormData[field] = val;

    setFormData(newFormData);
    toggleButtonDisabled(newFormData);
  }

  const toggleButtonDisabled = (newFormState) => {
    const { email, password } = newFormState;

    let disableText = [];
    if (!validateEmail(email)) {
      setButtonDisabled(true);
      disableText.push('Double check your email');
    }

    if (password.length < 1) {
      setButtonDisabled(true);
      disableText.push('Missing a password');
    }

    disableText.length === 0
      ? setButtonDisabled(false)
      : setDisableReason(disableText.join('\n'));
  }

  return (
    <View>
      <AuthScreenNav />

      <ScrollView style={styles.container} keyboardShouldPersistTaps="handled">
        <Icon fileName="shield_blue" addedStyles={{ alignSelf: 'center', marginBottom: distance.large }} />

        {backendErrors && <Flash messages={backendErrors} onExit={() => setBackendErrors(null)} />}

        <FieldSet
          name="email"
          required={true}
          placeholder="luke@skywalker.starwars"
          value={formData.email}
          label="Email"
          handleChange={(val) => handleChange('email', val)}
          autoFocus={true}
        />

        <FieldSet
          name="password"
          required={true}
          placeholder="***********"
          value={formData.password}
          label="Password"
          type="password"
          handleChange={(val) => handleChange('password', val)}
          autoFocus={false}
        />

        <Text onPress={() => navigate("/forgot-password")} style={styles.forgotPassword}>
          Forgot password?
        </Text>

        <Button
          async
          title="Login"
          onPress={onSubmit}
          addedStyles={{ backgroundColor: colors.green }}
          disabled={buttonDisabled}
          disabledText={disableReason}
        />

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 20,
  },
  forgotPassword: {
    color: colors.blue.light,
    textDecorationLine: 'underline',
    fontSize: fontSize.xsmall,
    alignSelf: 'flex-end',
    marginTop: -distance.small,
    marginBottom: distance.small
  }
});

export default LoginScreen;
