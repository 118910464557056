import React from 'react';

import BaseScreen from '../../components/screen_specific/BaseScreen';
import EventList from '../../components/screen_specific/event_index/List';

export default function EventIndex({ indexType }) {
  return (
    <BaseScreen
      component={EventList}
      componentProps={{ type: indexType }}
      addPaddingTopToMainContainer={false}
    />
  );
}
