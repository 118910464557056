import React, { useEffect } from 'react';
import { Image, TouchableOpacity, View } from 'react-native-web';

import { isAndroid, isIphone } from '../../../helpers/is_mobile';
import { appStoreUrl, playStoreUrl } from '../../../App';
import appStoreSrc from '../../../assets/img/app-store-icon.png';
import googlePlayStoreSrc from '../../../assets/img/google-play-icon.png';

export default function Body() {

  useEffect(() => {
    if (isIphone()) {
      window.location.href = appStoreUrl;
    } else if (isAndroid()) {
      window.location.href = playStoreUrl;
    }
  }, []);

  return (
    <View style={{ padding: 40, height: '100%', width: '100%', justifyContent: 'space-around' }}>
      <TouchableOpacity onPress={() => window.location.href = appStoreUrl}>
        <Image style={{ height: 100, borderRadius: 10 }} source={{ uri: appStoreSrc }} />
      </TouchableOpacity>

      <View style={{ marginVertical: 20 }} />

      <TouchableOpacity onPress={() => window.location.href = playStoreUrl}>
        <Image style={{ height: 100, borderRadius: 10 }} source={{ uri: googlePlayStoreSrc }} />
      </TouchableOpacity>
    </View>
  );
}
