import Bell from './bell';
import BellNotification from './bell_notification';
import User from './user';
import Home from './home';
import ArrowLeft from './arrow_left';
import ChevronLeft from './chevron_left';
import ChevronRight from './chevron_right';
import ChevronDown from './chevron_down';
import ChevronUp from './chevron_up';
import Calendar from './calendar';
import CalendarSmall from './calendar_small';
import MagnifyingEmpty from './magnifying_empty';
import Users from './users';
import Details from './details';
import EmptyCart from './empty_cart';
import Title from './title';
import DateTime from './date_time';
import Address from './address';
import EventDetails from './event_details';
import CircleGreenCheck from './circle_green_check';
import CircleTealQuestion from './circle_teal_question';
import CircleRedX from './circle_red_x';
import Trash from './trash';
import BurgerMenu from './burger_menu';
import BurgerMenuNotification from './burger_menu_notification';
import CloseX from './close_x';
import SettingsCog from './cog';
import RsvpReject from './rsvp_reject';
import RsvpPending from './rsvp_pending';
import RsvpAccept from './rsvp_accept';
import EmptyTray from './empty_tray';
import ShieldBlue from './shield_blue';
import ShieldGreen from './shield_green';
import RedX from './circle_white_x';
import MagnifyingGlass from './magnifying';
import Shrug from './shrug';
import Megaphone from './megaphone';
import Login from './login';
import Signup from './signup';
import CogNotification from './cog_notification';

const fileNameToComponents = {
  'bell': Bell,
  'bell_notification': BellNotification,
  'user': User,
  'home': Home,
  'arrow_left': ArrowLeft,
  'chevron_left': ChevronLeft,
  'chevron_right': ChevronRight,
  'chevron_down': ChevronDown,
  'chevron_up': ChevronUp,
  'calendar': Calendar,
  'calendar_small': CalendarSmall,
  'magnifying_empty': MagnifyingEmpty,
  'users': Users,
  'details': Details,
  'empty_cart': EmptyCart,
  'date_time': DateTime,
  'address': Address,
  'title': Title,
  'event_details': EventDetails,
  'circle_green_check': CircleGreenCheck,
  'circle_teal_question': CircleTealQuestion,
  'circle_red_x': CircleRedX,
  'trash': Trash,
  'burger_menu': BurgerMenu,
  'burger_menu_notification': BurgerMenuNotification,
  'close_x': CloseX,
  'cog': SettingsCog,
  'rsvp_reject': RsvpReject,
  'rsvp_pending': RsvpPending,
  'rsvp_accept': RsvpAccept,
  'empty_tray': EmptyTray,
  'shield_blue': ShieldBlue,
  'shield_green': ShieldGreen,
  'red_x': RedX,
  'magnifying': MagnifyingGlass,
  'shrug': Shrug,
  'megaphone': Megaphone,
  'login': Login,
  'signup': Signup,
  'cog_notification': CogNotification,
};

export default fileNameToComponents;
