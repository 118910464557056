import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native-web';

import icons from './index';

export default function Icon({ onPress, fileName, addedStyles = {}, btnRef = null }) {
  const IconComponent = icons[fileName];

  return (
    <TouchableWithoutFeedback onPress={onPress} ref={btnRef || null}>
      <View style={addedStyles}>
        <IconComponent />
      </View>
    </TouchableWithoutFeedback>
  )
};
