import { View } from "react-native-web";
import { DesktopNav } from "../components/navbar/Desktop";
import MobileNav from "../components/navbar/Mobile";
import { Features } from "../components/features";
import { About } from "../components/about";
// import { Testimonials } from "../components/testimonials";
import { Footer } from "../components/footer/Desktop";
import { isMobile } from "../helpers/is_mobile";

export const Homepage = () => {
    return (
        <div>
            <View style={{ zIndex: 100 }}>
                {isMobile() ? <MobileNav /> : <DesktopNav />}
            </View>
            <About />
            <Features />
            {/* These aren't actual testimonials, they're FAQs */}
            {/* <Testimonials /> */}
            <Footer />
        </div>
    )
}
