export const SET_EVENTS = 'set_events';
export const ADD_EVENT = 'add_event';
export const ADD_EVENTS = 'add_events';

const _defaultState = {
  hosted: null,
  upcoming: null,
};

export default function EventsReducer(state = _defaultState, action) {
  const dupState = JSON.parse(JSON.stringify(state));
  switch (action.type) {
    case SET_EVENTS:
      return action.value;
    case ADD_EVENT:
      dupState[action.value.id] = action.value;
      return dupState;

    case ADD_EVENTS:
      dupState[action.eventType] ||= [];
      const newSection = [ ...dupState[action.eventType], ...action.value ];
      dupState[action.eventType] = newSection;
      return dupState;
    default:
      return state;
  }
}
