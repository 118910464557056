import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

global.gathrUp ||= {};
global.gathrUp.locale = navigator.language;
global.gathrUp.language = navigator.language.split('-')[0];

// Do not do this for production. Instead setup a Bugsnag project, and add the API key to the .env in prod
// Only do this for development. Meaning we don't need to log to bugsnag during development/test.
global.gathrUp.Bugsnag = { notify: (e) => { console.log(e) }}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
