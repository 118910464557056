import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View, TextInput, Keyboard } from "react-native";

import styleGuide from "../../assets/styleGuide";
const { colors, distance } = styleGuide;

export default function FieldSet({
  label,
  required,
  placeholder,
  type,
  keyboardType,
  handleChange,
  textArea,
  autoFocus,
  value
}) {
  const [focused, setFocused] = useState(false);
  const [keyboardOffset, setKeyboardOffset] = useState(0);
  const onKeyboardShow = event => setKeyboardOffset(event.endCoordinates.height - 50);
  const onKeyboardHide = () => setKeyboardOffset(0);
  const keyboardDidShowListener = useRef();
  const keyboardDidHideListener = useRef();

  useEffect(() => {
    if (!textArea) return;

    keyboardDidShowListener.current = Keyboard.addListener('keyboardWillShow', onKeyboardShow);
    keyboardDidHideListener.current = Keyboard.addListener('keyboardWillHide', onKeyboardHide);

    return () => {
      keyboardDidShowListener.current.remove();
      keyboardDidHideListener.current.remove();
    };
  }, [textArea]);

  return (
    <View style={{ marginBottom: focused ? keyboardOffset : 0 }}>
      <View>
        <Text>{label + (required ? ' *' : '')}</Text>
          <TextInput
            style={{
              ...styles.input,
              ...(textArea ? styles.textArea : {})
            }}
            onChangeText={handleChange}
            value={value}
            placeholder={placeholder}
            keyboardType={keyboardType || 'default'}
            secureTextEntry={type === 'password'}
            multiline={textArea || false}
            numberOfLines={textArea ? 2 : 1}
            onFocus={() => setFocused(true)}
            autoFocus={autoFocus || false}
            placeholderTextColor={colors.gray.darkest}
          />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    marginBottom: distance.large,
    marginTop: distance.xsmall,
    backgroundColor: colors.gray.lighter,
    height: 50,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.gray.lighter,
    paddingLeft: distance.medium,
    paddingRight: distance.medium,
  },
  erroredInput: {
    borderColor: colors.red.light,
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
    paddingLeft: distance.medium,
    paddingRight: distance.medium,
    paddingTop: distance.large,
  },
  textArea: {
    height: 100,
    paddingTop: distance.medium,
    paddingBottom: distance.medium,
  }
});
