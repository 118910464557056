export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LOADING = 'SET_LOADING';
export const STORE_PATH = 'STORE_PATH';

const _defaultState = {
  currentUser: null,
  loading: true,
  storedPath: null
}

export default function AuthReducer(state = _defaultState, action) {
  const dupState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case SET_CURRENT_USER:
      dupState.currentUser = action.value;
      dupState.loading = false;
      return dupState;
    case SET_LOADING:
      dupState.loading = action.value;
      return dupState;
    case STORE_PATH:
      dupState.storedPath = action.value;
      return dupState;
    default:
      return state;
  }
}
