import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
<Svg width={24} height={24} fill="none"
  xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
<Path d="M19.25 21a.75.75 0 0 0 1.5 0h-1.5ZM16 15v-.75.75Zm-8 0v-.75.75Zm-4 4h-.75H4Zm-.75 2a.75.75 0 0 0 1.5 0h-1.5Zm17.5 0v-2h-1.5v2h1.5Zm0-2c0-1.26-.5-2.468-1.391-3.359l-1.06 1.06c.609.61.951 1.437.951 2.299h1.5Zm-1.391-3.359A4.75 4.75 0 0 0 16 14.25v1.5a3.25 3.25 0 0 1 2.298.952l1.06-1.06ZM16 14.25H8v1.5h8v-1.5Zm-8 0c-1.26 0-2.468.5-3.359 1.391l1.06 1.06A3.25 3.25 0 0 1 8 15.75v-1.5Zm-3.359 1.391A4.75 4.75 0 0 0 3.25 19h1.5c0-.862.342-1.689.952-2.298l-1.06-1.06ZM3.25 19v2h1.5v-2h-1.5Zm12-12A3.25 3.25 0 0 1 12 10.25v1.5A4.75 4.75 0 0 0 16.75 7h-1.5ZM12 10.25A3.25 3.25 0 0 1 8.75 7h-1.5A4.75 4.75 0 0 0 12 11.75v-1.5ZM8.75 7A3.25 3.25 0 0 1 12 3.75v-1.5A4.75 4.75 0 0 0 7.25 7h1.5ZM12 3.75A3.25 3.25 0 0 1 15.25 7h1.5A4.75 4.75 0 0 0 12 2.25v1.5Z" fill="#1A1A1A" />
</Svg>
)

export default SvgComponent
