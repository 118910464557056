import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M17.47 15.53a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM12 9l.53-.53a.75.75 0 0 0-1.06 0L12 9Zm-6.53 5.47a.75.75 0 1 0 1.06 1.06l-1.06-1.06Zm13.06 0-6-6-1.06 1.06 6 6 1.06-1.06Zm-7.06-6-6 6 1.06 1.06 6-6-1.06-1.06Z"
      fill="#1A1A1A"
    />
  </Svg>
)

export default SvgComponent
