import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={43}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M33.448 23.088a.75.75 0 1 0 0-1.5v1.5Zm-24.646-.75-.533-.527a.75.75 0 0 0 0 1.054l.533-.527Zm11.79 12.995a.75.75 0 1 0 1.066-1.055l-1.066 1.055Zm1.066-24.935a.75.75 0 0 0-1.066-1.055l1.066 1.055Zm11.79 11.19H8.802v1.5h24.646v-1.5Zm-11.79 12.69L9.335 21.811 8.27 22.865l12.323 12.468 1.066-1.055ZM9.335 22.865l12.323-12.467-1.066-1.055L8.269 21.811l1.066 1.054Z"
      fill="#1A1A1A"
    />
  </Svg>
)

export default SvgComponent
