import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Switch, ScrollView, ActivityIndicator } from 'react-native-web';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import Button from '../../Button';

import languageSpecificText from '../../../helpers/languages';
import { getUser, updateUser } from '../../../utils/api/users';
import { SET_FLASH } from '../../../reducers/FlashReducer';
import styleGuide from '../../../assets/styleGuide';
import Icon from '../../../assets/icons/base';
const { fontSize, colors, distance } = styleGuide

export default function Body() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentUser } = useSelector(state => state.auth);

  const [notifSettings, setNotifSettings] = useState({
    notif_event_cancellation: null,
    notif_event_info_changes: null,
    notif_event_items_added: null,
    notif_event_reminders: null,
    notif_event_respond: null,
    notif_event_announcement: null,
    notif_event_access_request: null,
    notif_event_access_request_granted: null,
    notif_event_access_request_denied: null,
    notif_event_access_revoked: null,
  });

  useEffect(() => {
    (async () => {
      const res = await getUser(currentUser.id);
      const newState = { ...notifSettings, ...(res.user?.settings || {}) };
      setNotifSettings(newState);
    })()
    // eslint-disable-next-line
  }, [currentUser.id, notifSettings.notif_event_access_request])

  const updateNotifSetting = (setting) => {
    const newState = { ...notifSettings };
    newState[setting] = !newState[setting];
    setNotifSettings(newState);
  }

  const handleSave = async () => {
    const res = await updateUser({ settings: notifSettings });

    if (res.errors) {
      dispatch({ type: SET_FLASH, isSuccess: false, messages: res.errors })
    } else {
      dispatch({ type: SET_FLASH, isSuccess: true, messages: ["Success"] })
    }
  }

  if (notifSettings.notif_event_cancellation === null) {
    return <ActivityIndicator size="large" />
  }

  return (
    <View style={{ flex: 1, position: 'relative', padding: distance.medium }}>

      {/* This large padding bottom is to counter the button position: absolute */}
      <ScrollView showsVerticalScrollIndicator={false} >


        {/* EMAIL VERIFIED AND LINK TO VERIFY */}
        <Text style={styles.sectionHeader}>Email</Text>
        <View style={{ marginBottom: distance.xlarge }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={{ fontSize: fontSize.large }}>{currentUser.email}</Text>
            <Icon fileName={currentUser.emailVerified ? 'circle_green_check' : 'circle_red_x'} />
          </View>

          {
            !currentUser.emailVerified && (
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: distance.medium }}>
                <Text style={{ flex: 3, color: colors.gray.darkest }}>Before you can attend or host your own events your email must be verified</Text>
                <Button
                  hollow
                  title="Verify"
                  onPress={() => navigate("/verify-email")}
                  addedStyles={{ flex: 1, marginTop: 0, marginLeft: distance.small }}
                />
              </View>
            )
          }
        </View>

        {/* NOTIFICATIONS SETTINGS */}
        <Text style={styles.sectionHeader}>{languageSpecificText('settings', 'headers', 'notifications')}</Text>
        <View style={{ marginBottom: distance.small }}>
          {
            Object.keys(notifSettings).map((key, idx) => {
              const settingType = key;
              const settingValue = notifSettings[settingType];

              return (
                <View key={idx} style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: distance.small }}>
                  <View style={{ width: '80%' }}>
                    <Text style={{ fontSize: fontSize.medium }}>
                      {languageSpecificText('settings', 'notifs', 'titles', settingType)}
                    </Text>
                    <Text style={{ fontSize: fontSize.small, color: colors.gray.darker }}>
                      {languageSpecificText('settings', 'notifs', 'descriptions', settingType)}
                    </Text>
                  </View>
                  <Switch
                    value={settingValue}
                    onValueChange={() => updateNotifSetting(settingType)}
                    trackColor={{ true: colors.blue.light, false: colors.red.light }}
                  />
                </View>
              )
            })
          }
        </View>

      </ScrollView>
      <Button
        async
        title={languageSpecificText('general', 'save')}
        onPress={handleSave}
        addedStyles={{ backgroundColor: colors.green }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  sectionHeader: {
    fontWeight: 'bold',
    fontSize: fontSize.subTitle,
    marginBottom: distance.small
  },
});
