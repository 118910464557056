import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
<Svg width={24} height={24} fill="none"
  xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
<Path d="m3 9-.46-.592A.75.75 0 0 0 2.25 9H3Zm9-7 .46-.592a.75.75 0 0 0-.92 0L12 2Zm9 7h.75a.75.75 0 0 0-.29-.592L21 9ZM3 20h-.75H3Zm5.25 2a.75.75 0 0 0 1.5 0h-1.5ZM9 12v-.75a.75.75 0 0 0-.75.75H9Zm6 0h.75a.75.75 0 0 0-.75-.75V12Zm-.75 10a.75.75 0 0 0 1.5 0h-1.5ZM3.46 9.592l9-7-.92-1.184-9 7 .92 1.184Zm8.08-7 9 7 .92-1.184-9-7-.92 1.184ZM20.25 9v11h1.5V9h-1.5Zm0 11c0 .331-.132.65-.366.884l1.06 1.06A2.75 2.75 0 0 0 21.75 20h-1.5Zm-.366.884a1.25 1.25 0 0 1-.884.366v1.5c.73 0 1.429-.29 1.945-.805l-1.061-1.061ZM19 21.25H5v1.5h14v-1.5Zm-14 0a1.25 1.25 0 0 1-.884-.366l-1.06 1.06A2.75 2.75 0 0 0 5 22.75v-1.5Zm-.884-.366A1.25 1.25 0 0 1 3.75 20h-1.5c0 .73.29 1.429.805 1.945l1.061-1.061ZM3.75 20V9h-1.5v11h1.5Zm6 2V12h-1.5v10h1.5ZM9 12.75h6v-1.5H9v1.5Zm5.25-.75v10h1.5V12h-1.5Z" fill="#1A1A1A" />
</Svg>
)

export default SvgComponent
