import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native-web';
import { useSelector } from 'react-redux';

import ShowProfile from './Show';

import { getUser } from '../../../utils/api/users';
import { useParams } from 'react-router-dom';

export default function Body() {
  const { id: userId } = useParams();

  const [user, setUser] = useState({});
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    (async () => {
      const res = await getUser(userId || currentUser.id);
      setUser(res.user);
    })();
  }, [userId, currentUser.id])

  if (!user.id) {
    return <View style={{ flex: 1, justifyContent: 'center' }}><ActivityIndicator size="large" /></View>;
  }

  return <ShowProfile user={user} />;
}
