import React, { useEffect, useState } from 'react';
import { View, Text, StyleSheet, Image } from 'react-native-web';

import { getAwsImgUrl } from '../utils/aws';
import styleGuide from '../assets/styleGuide';
const { buttons, colors, distance } = styleGuide;

export const UserImage = ({ name, image, size = 'regular' }) => {
  const [sourceUrl, setSourceUrl] = useState(null);

  useEffect(() => {
    (async () => {
      if (image) {
        const res = await getAwsImgUrl(image);
        setSourceUrl(res);
      }
    })()
  }, [image]);

  let imgStyle = {
    height: buttons.round[size].height,
    width: buttons.round[size].width,
    borderRadius: buttons.round[size].borderRadius,
  };

  if (!name && !image) return (
    <View style={{ ...styles.imgWrap, ...imgStyle }}><Text>?</Text></View>
  );

  let innerComponent;
  if (image) {
    innerComponent = <Image style={imgStyle} source={{ uri: sourceUrl }} />
  } else {
    let display = '?';
    if (name) display = name.split(' ').slice(0, 2).map(w => w[0]?.toUpperCase());
    innerComponent = <Text>{display}</Text>
  }

  let imgWrap = { ...styles.imgWrap };
  imgWrap.height = buttons.round[size].height;
  imgWrap.width = buttons.round[size].width;
  imgWrap.borderRadius = buttons.round[size].borderRadius;

  return (
    <View style={imgWrap}>{innerComponent}</View>
  )
}

export const ProfileImg = ({ name, image }) => {
  const [sourceUrl, setSourceUrl] = useState(null);

  useEffect(() => {
    (async () => {
      if (image) {
        const res = await getAwsImgUrl(image);
        setSourceUrl(res);
      }
    })()
  }, [image])

  const handleImgLoadErr = ({ nativeEvent: { error } }) => {
    global.gathrUp?.Bugsnag?.notify(`Error loading img: ${error}`)
  }

  let innerComponent;
  if (image && sourceUrl) {
    innerComponent = <Image style={styles.proImg} source={{ uri: sourceUrl }} onError={handleImgLoadErr} />
  } else {
    let display = '?';
    if (name) display = name.split(' ').slice(0, 2).map(w => w[0]?.toUpperCase());
    innerComponent = <Text style={{ fontSize: 40 }}>{display}</Text>
  }

  return (
    <View style={styles.proImgWrap}>{innerComponent}</View>
  )
}

const styles = StyleSheet.create({
  imgWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.gray.lightest,
    marginRight: distance.large
  },
  img: {
    height: buttons.round.regular.height,
    width: buttons.round.regular.width,
    borderRadius: buttons.round.regular.borderRadius,
  },
  proImgWrap: {
    height: 180,
    width: 180,
    borderRadius: 90,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.gray.lightest,
    marginRight: distance.large,
  },
  proImg: {
    height: 180,
    width: 180,
    borderRadius: 90,
  },
});
