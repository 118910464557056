export const storeAuth = (authInfo) => {
  const { id, name, token, email, emailVerified } = authInfo;

  try {
    if (!id || !name || !token ||!email) throw new Error("Auth err");
    window.localStorage.setItem("gathrup-authInfo:id", id);
    window.localStorage.setItem("gathrup-authInfo:name", name);
    window.localStorage.setItem("gathrup-authInfo:token", token);
    window.localStorage.setItem("gathrup-authInfo:email", email);
    window.localStorage.setItem("gathrup-authInfo:emailVerified", emailVerified);
  } catch (e) {
    global.gathrUp.Bugsnag.notify(new Error('Error storing auth info'));
    return false;
  }

  return true;
}

export const getStoredAuth = () => {
  try {
    const idCookie = window.localStorage.getItem("gathrup-authInfo:id");
    const nameCookie = window.localStorage.getItem("gathrup-authInfo:name");
    const tokenCookie = window.localStorage.getItem("gathrup-authInfo:token");
    const emailCookie = window.localStorage.getItem("gathrup-authInfo:email");
    const emailVerified = window.localStorage.getItem("gathrup-authInfo:emailVerified");

    if (idCookie && nameCookie && tokenCookie && emailCookie) {
      return { id: idCookie, name: nameCookie, token: tokenCookie, email: emailCookie, emailVerified };
    }
    return null;
  } catch (e) {
    global.gathrUp.Bugsnag.notify(new Error('Error getting stored auth info'));
    return null;
  }
}

export const clearStoredAuth = () => {
  try {
    window.localStorage.removeItem("gathrup-authInfo:id");
    window.localStorage.removeItem("gathrup-authInfo:name");
    window.localStorage.removeItem("gathrup-authInfo:token");
    window.localStorage.removeItem("gathrup-authInfo:email");
    window.localStorage.removeItem("gathrup-authInfo:emailVerified");

  } catch (e) {
    global.gathrUp.Bugsnag.notify(new Error('Error clearing stored auth info'));
    return null;
  }
}

export const getStoredAwsCred = () => {
  try {
    const jsonValue = window.localStorage.getItem('gathrup_aws_creds');
    if (jsonValue === null) return null;
    return JSON.parse(jsonValue);
  } catch (e) {
    global.gathrUp.Bugsnag.notify(new Error('Error getting stored user aws creds'));
    return null;
  }
};

export const storeAwsCreds = async (creds) => {
  try {
    window.localStorage.setItem("gathrup_aws_creds", JSON.stringify(creds));
    return true;
  } catch (e) {
    global.gathrUp.Bugsnag.notify(new Error('Error storing user aws creds'));
    return false;
  }
};
