import { getStoredAuth } from "../storage";

export const makeAuthRequest = async (path, method, body) => {
  const authInfo = getStoredAuth();
  if (!authInfo?.token) return null;
  const requestOptions = {
    method,
    headers: {
      'Authorization': authInfo.token,
      'Content-Type': 'application/json'
    }
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }

  const endpoint = process.env.REACT_APP_API_HTTP_ENDPOINT;
  let res = await fetch(endpoint + path, requestOptions);

  res = await res.json();

  return res;
}
