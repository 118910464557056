import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native-web";
import Icon from "../assets/icons/base";

import styleGuide from "../assets/styleGuide";
import languageSpecificText from "../helpers/languages";
const { colors, distance } = styleGuide;

export default function Flash({ messages, success=false, onExit, addedStyles = {} }) {
  const containerStyles = { ...styles.container, backgroundColor: success ? colors.green : colors.red.light, ...addedStyles };

  messages = messages.map(msg => languageSpecificText('backendErrors', msg) || msg)

  return (
    <View style={containerStyles}>
      <View style={styles.messages}>
        {
          messages.map((message, idx) => {
            return (
              <Text key={idx} style={styles.message}>{`•  ${message}`}</Text>
            )
          })
        }
      </View>

      <TouchableOpacity
        onPress={onExit}
        style={{
          height: 24,
          width: 24,
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderRadius: 12
        }}>
        <Icon fileName="red_x" onPress={onExit}/>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    color: colors.white,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 5,
    position: 'absolute',
    zIndex: 100,
    padding: distance.small,
    paddingTop: distance.medium,
    paddingBottom: distance.medium,
    shadowColor: colors.gray.darkest,
    shadowOffset: {
      width: 3,
      height: 3
    },
    shadowOpacity: 0.2
  },
  messages: {
    justifyContent: 'center',
    width: '90%',
  },
  message: {
    color: colors.white,
  },
  exit: {
    padding: distance.xsmall
  },
  exitText: {
    color: colors.white,
  }
});
