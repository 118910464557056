import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native-web';

import Button from '../components/Button';
import Flash from '../components/Flash';
import Icon from '../assets/icons/base';
import FieldSet from '../components/forms/FieldSet';
import AuthScreenNav from '../components/navbar/AuthScreen';

import { sendResetPasswordLink } from '../utils/api/users';

import styleGuide from '../assets/styleGuide';
import languageSpecificText from '../helpers/languages';
const { distance } = styleGuide;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function ForgotPassword() {
  const [backendErrors, setBackendErrors] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disableReason, setDisableReason] = useState('Missing email');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const onSubmit = async () => {
    const res = await sendResetPasswordLink(email);

    if (res.success === true) {
      setBackendErrors(null);
      setSuccess(true);
    } else {
      setBackendErrors([res.message]);
      setSuccess(false);
    }
  };

  const handleEmailChange = (val) => {
    setEmail(val);

    if (!validateEmail(val)) {
      setButtonDisabled(true);
      setDisableReason('Double check your email');
    } else {
      setButtonDisabled(false);
    }
  }

  return (
    <View>
      <AuthScreenNav />
      <View style={styles.container} keyboardShouldPersistTaps="handled">

        <Icon fileName="shield_blue" addedStyles={{ alignSelf: 'center', marginBottom: distance.small }} />

        {backendErrors && <Flash messages={backendErrors} onExit={() => setBackendErrors(null)} />}
        {success && <Flash success={true} messages={["Successfully sent email"]} onExit={() => setBackendErrors(null)} />}

        <FieldSet
          name="email"
          required={true}
          placeholder="luke@star.wars"
          value={email}
          label="Email"
          handleChange={handleEmailChange}
        />

        <Button
          async
          title={languageSpecificText('auth', 'sendLink')}
          onPress={onSubmit}
          addedStyles={{ backgroundColor: styleGuide.colors.green, marginBottom: styleGuide.distance.xlarge }}
          disabled={buttonDisabled}
          disabledText={disableReason}
          tooltipPlacement="top"
        />

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: styleGuide.colors.white,
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 20,
  },
});
