import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Text, View } from 'react-native-web';
import { useLocation, useNavigate } from 'react-router-dom';

import styleGuide from '../../assets/styleGuide';
import languageSpecificText from '../../helpers/languages';
const { colors, distance, fontSize } = styleGuide;

export default function AuthScreenNav() {
  const location = useLocation();
  const navigate = useNavigate();

  const [navLink, setNavLink] = useState('/login');
  const [navText, setNavText] = useState(languageSpecificText('screenTitles', 'login'));
  const [screenTitle, setScreenTitle] = useState(languageSpecificText('screenTitles', 'signup'))

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === '/login') {
      setNavLink('/signup');
      setNavText(languageSpecificText('screenTitles', 'signup'));
      setScreenTitle(languageSpecificText('screenTitles', 'login'))
    } else if (pathname === '/signup') {
      setNavLink('/login');
      setNavText(languageSpecificText('screenTitles', 'login'));
      setScreenTitle(languageSpecificText('screenTitles', 'signup'));
    } else if (pathname === '/reset-password') {
      setNavLink('/login');
      setNavText(languageSpecificText('screenTitles', 'login'));
      setScreenTitle(languageSpecificText('screenTitles', 'resetPassword'));
    } else if (pathname === '/forgot-password') {
      setNavLink('/login');
      setNavText(languageSpecificText('screenTitles', 'login'));
      setScreenTitle(languageSpecificText('screenTitles', 'forgotPassword'));
    }
  }, [location.pathname]);

  return (
    <View style={{ backgroundColor: colors.gray.lighter, height: 90, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: distance.large }}>
      <Text
        onPress={() => navigate(navLink)}
        style={{ color: colors.blue.light, textDecoration: 'underline', fontSize: fontSize.medium }}
      >
        {navText}
      </Text>
      <Text style={{ fontSize: fontSize.title }}>{screenTitle}</Text>
    </View>
  )
}
