import React from 'react';

import BaseScreen from '../../components/screen_specific/BaseScreen';
import AttendeeItemsRegistry from '../../components/screen_specific/event_show/Items';

export default function EventItemsScreen() {
  return (
    <BaseScreen
      component={AttendeeItemsRegistry}
      addPaddingTopToMainContainer={false}
    />
  );
}
