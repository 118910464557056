import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobile } from '../../helpers/is_mobile';
import languageSpecificText from '../../helpers/languages';
import Icon from '../../assets/icons/base';
import { useRef } from 'react';
import { useEffect } from 'react';

export const DesktopNav = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const navBarLinksRef = useRef(null);
  const burgerRef = useRef(null);

  useEffect(() => {
    if (burgerRef.current) {
      burgerRef.current.classList.add('burger-icon');
    }
  }, []);

  const toggleNavBarLinks = () => {
    const currentState = navBarLinksRef.current.style.display;
    const nextState = (!currentState || currentState === 'none') ? 'block' : 'none';
    navBarLinksRef.current.style.display = nextState;
  };

  return (
    <nav id="menu" className="navbar navbar-default">
      <div className="container">
        <div className="navbar-logo-and-burger">
          <a className="page-scroll logo-link" href="/">
            <img src="img/logo-large.png" className="gathrup-logo" alt="logo" />
          </a>

          <Icon fileName="burger_menu" onPress={toggleNavBarLinks} btnRef={burgerRef} />
        </div>

        <div
          className="navbar-links"
          id="bs-example-navbar-collapse-1"
          ref={navBarLinksRef}
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <NavLink to="/">
                {languageSpecificText('screenTitles', 'home')}
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact">
                {languageSpecificText('screenTitles', 'contact')}
              </NavLink>
            </li>

            {
              isMobile() && (
                currentUser ? (
                  <>
                    <li>
                      <NavLink to="/profile">{languageSpecificText('screenTitles', 'profile')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/notifications">{languageSpecificText('screenTitles', 'notifications')}</NavLink>
                    </li>
                    <li>
                      <NavLink to="/events/upcoming">{languageSpecificText('screenTitles', 'upcomingEvents')}</NavLink>
                    </li>
                  </>
                )
                : (
                  <>
                    <li>
                        <NavLink to="/login">{languageSpecificText('screenTitles', 'login')}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/signup">{languageSpecificText('screenTitles', 'signup')}</NavLink>
                    </li>
                  </>
                )
              )
            }
          </ul>
        </div>
      </div>
    </nav>
  )
}
