import { DesktopNav } from "../components/navbar/Desktop";
import { Privacy } from "../components/privacy";
import { Footer } from "../components/footer/Desktop";
import { View } from "react-native-web";
import { isMobile } from "../helpers/is_mobile";
import MobileNav from "../components/navbar/Mobile";

export const PrivacyPage = () => {

    return (
        <div>
            <View style={{ zIndex: 100 }}>
                {isMobile() ? <MobileNav /> : <DesktopNav />}
            </View>
            <Privacy />
            <Footer />
        </div>
    )
}
