export const SET_EVENT_ID = 'set_event_id';

const _defaultState = {
  invites: {},
  eventId: null,
};

export default function HostAttendeesReducer(state = _defaultState, action) {
  const dupState = JSON.parse(JSON.stringify(state));

  switch (action.type) {
    case SET_EVENT_ID:
      dupState.eventId = action.value;
      dupState.invites[action.value] ||= {};
      return dupState;
    default:
      return state;
  }
}
