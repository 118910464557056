import { appStoreUrl, playStoreUrl } from "../App";

export const About = () => {
  return (
    <div id="about">
      <div className="container">
        <div className="row flex-center">
          <div className="col-xs-12 col-md-6">
            <div className="about-text text-center">
              <h2>Let’s Get Together <br /> & <br /> GathrUp</h2>
              <hr />
              <p>GathrUp lets you quickly send out an invite to your contacts, track who’s coming, see who is bringing what, and more.</p>
            </div>
          </div>
        </div>

        <div className="row flex-center">
          <div className="col-xs-12 col-md-6">
            <div className="about-text text-center">
              <a href="/download" className="download-label">Download the App</a>
              <div className="download-links">
                <div className="col-lg-6 col-sm-6 col-xs-6">
                  <a href={appStoreUrl}><img src="img/app-store-icon.png" className="img-responsive img-2" alt="" /></a>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-6">
                  <a href={playStoreUrl}><img src="img/google-play-icon.png" className="img-responsive img-2" alt="" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
