const MONTH_NAMES = {
  en: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  es: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
}

export function getCurrentDate() {
  let date = new Date();
  return new Intl.DateTimeFormat('en-US').format(date);
}

export function getShortIntlDate(date) {
  const d = new Date(date);
  const locale = navigator.language || 'en-US';
  return new Intl.DateTimeFormat(locale).format(d);
}

export function formatDateForCalendar(date) {
  const d = new Date(date);
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}

export function getHourAndMinute(date) {
  date = new Date(date);
  let hr = date.getHours()
  let min = date.getMinutes()

  const amOrPm = hr >= 12 ? 'PM' : 'AM';
  if (hr > 12) hr %= 12;
  if (hr === 0) hr = 12;
  if (min === 0) min = '00';

  return `${hr}:${min} ${amOrPm}`;
}

export function getMonthAndDate(date) {
  date = new Date(date);

  const locale = navigator.language || 'en-US';
  const lang = locale.split('-')[0];

  const monthIdx = date.getMonth();
  const mo = lang in MONTH_NAMES
    ? MONTH_NAMES[lang][monthIdx]
    : MONTH_NAMES.en[monthIdx];

  const dt = date.getDate();
  return `${mo} ${dt}`;
}

export function dateIsFuture(date) {
  const startOfDay = new Date();
  startOfDay.setUTCHours(0, 0, 0, 0);

  return startOfDay <= new Date(date);
}

export function eventDateIsFuture(date) {
  return new Date() <= new Date(date);
}

export function calDayIsInPast(date) {
  const startOfDay = new Date(getCurrentDate());
  startOfDay.setUTCHours(0, 0, 0, 0);

  return startOfDay > new Date(date);
}

export function getNextDate(date) {
  const day = new Date(date);
  day.setDate(day.getDate() + 1);
  return day;
}

export function datePlus12Hours(date) {
  const dayTime = new Date(date);
  dayTime.setTime(dayTime.getTime() + (12 * 60 * 60 * 1000));
  return dayTime;
}

export function yesterday() {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
}

export function today() {
  return new Date();
}

export function setToHalfHour(date) {
  date.setMinutes(30);
  date.setSeconds(30);
  return date;
}
