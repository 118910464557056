const spanish = {
  screenTitles: {
    home: 'Inicio',
    calendar: 'Calendario',
    contact: 'Contactar',
    profile: 'Perfil',
    notifications: 'Notificaciones',
    settings: 'Ajustes',
    pastEvents: 'Anteriores',
    hostedEvents: 'Anfitrionados',
    upcomingEvents: 'Proximos',
    eventShow: 'Evento',
    eventNew: 'Nuevo Evento',
    hostDetailsManage: 'Ajustar Detalles',
    hostAttendeesManage: 'Administrar Invitados',
    hostItemsManage: 'Administrar Articulos',
    attendeeDetails: 'Detalles',
    attendeeRsvpList: 'Confirmaciones',
    attendeeItems: 'Articulos',
    signup: 'Inscripcion',
    login: 'Iniciar Sesion',
    verify: 'Verificar',
    resetPassword: 'Restablecer Contraseña',
    forgotPassword: 'Contraseña Olvidada',
  },
  burgerNav: {
    events: 'Eventos',
    createEvent: 'Crear Evento',
    logout: 'Cerrar Sesión',
  },
  home: {
    upcomingEvents: 'Proximos Eventos',
    itemsForEvent: 'Articulos para',
    registryItems: 'Articulos Contribuidos',
    pastEvents: 'Eventos Anteriores',
    createdEvents: 'Eventos Creados',
    futureEvents: 'Eventos Futuros',
    event: 'evento',
    date: 'fecha',
    rsvp: 'rsvp',
    item: 'articulo',
    owner: 'dueño',
    noItems: 'Este evento no tiene articulos.',
    noEvents: 'No tiene eventos a futuro! Crea un evento para comenzar.',
    calendarView: 'Vista de Calendario',
    calendar: 'Calendario',
    eventsInvited: 'Eventos Invitados',
    attending: 'Atendidos',
    rejected: 'Rechazados',
    pending: 'Sin respuesta',
  },
  events: {
    noEvents: 'Al parecer todavia no tiene eventos. Los eventos que aparecerian aqui son los que usted crea or a los que lo inviten.',
    noPastEvents: 'No hay ningun evento anterior. Eventos del pasado al que usted haya anfitrionado o fue un invitade aparecerian en esta pagina.',
    noUpcomingEvents: 'No hay próximos eventos. Eventos del futuro a los cuales usted fue invitade o haya creado aparecerían en esta pagina.',
    noHostedEvents: 'Parece que usted todavia no ha anfitrionado (creado) ningun evento. Solamente eventos que usted haya anfitrionado aparecen en esta pagina.\n\nEventos anfitrionados del pasado pueden ser recreados. Eventos recreatos incluyen los mismos detalles, atriculos, y lista de invitados, y todos los aspectos pueden ser actualizados antes de enviar invitaciones.',
    noEventsOnDay: 'No hay eventos este día!',
    noLocation: 'Ubicaión no especificada',
    noDetails: 'No hay mas detalles',
    noRsvps: 'Este evento no tiene RSVPs',
    createEvent: 'Crea un evento para comenzar.',
    create: 'Crear Evento',
    createDisabled: 'No se pueden crear eventos en el pasado',
    disabledDelete: 'No se puede borrar evento terminado',
    disabledResponse: 'No se puede RSVP a evento terminado',
    canceled: 'Evento cancelado por anfitrion',
    hostedBy: 'Anfitrionado por:',
    claim: 'reclamar',
    release: 'soltar',
    me: 'yo',
    deleteEvent: 'Borrar Evento',
    confirm: 'Confirmar',
    recreateEvent: 'Recrear Evento',
    cannotEdit: 'No puede actualizar el evento desde la web, use la aplicación en su lugar',
    show: {
      details: 'Detalles',
      rsvp: 'Lista RSVP',
      items: 'Articulos',
      hostDetails: 'Actualizar Detalles',
      hostRsvp: 'Invitaciones',
      hostItems: 'Ajustar Articulos',
      announcements: 'Anuncios',
      change: 'Cambiar',
      coming: 'Si, voy!',
      notComing: 'No, no puedo ir.',
      respond: 'Responder',
      addRsvp: 'Agregue su RSVP',
      cancel: 'Cancelar',
      when: 'cuando',
      where: 'donde',
      additional: 'adicional',
    },
    recreate: {
      modal: {
        recreate: 'Recrear Evento',
        items: 'Articulos',
        date: 'Fecha',
        invites: 'Invitaciones',
        cancel: 'Cancelar',
        button: 'Recrear'
      },
      items: 'Todos los articulos seran copiados al nuevo evento, y pueden ser actualizados.',
      details: 'Los detalles del nuevo evento tambien pueden ser ajustados, y la fecha deberia ser actualizada.',
      invites: 'Invitaciones no seran enviadas hasta que usted no las envie.',
      disabled: 'No puede recrear un evento del futuro'
    },
    form: {
      titleMissing: 'Título tiene que existir',
      titlePlaceholder: 'Noche de Pizza',
      title: 'Título',
      date: 'Fecha',
      time: 'Hora',
      locationLabel: 'Ubicación',
      detailsPlaceholder: 'Agregue detalles de su evento. Ejemplo: Por favor estacionar al final de la calle',
      detailsLabel: 'Detalles',
      formEmpty: 'Formulario vacío',
      noChanges: 'No hay cambios para guardar',
      create: 'Crear',
      update: 'Actualizar'
    },
    invites: {
      sent: 'Enviadas',
      invites: 'A invitar',
      sendInvites: 'Enviar Invitaciones'
    },
  },
  items: {
    addItemPlaceholder: 'Agregue un articulo...',
    noItems: 'Este evento no tiene articulos.',
    addItems: 'Agregue articulos para que sus invitados puedan colaborar y traer cosas a su evento.',
    awaitItems: 'Puede revisar esta pagina mas adelante para ver si el anfitrion agrego articulos para que los invitados puedan traer al evento.\n\nPuede ajustar las notificaciones acerca de los articulos en la pagina de Ajustes.',
  },
  announcements: {
    attendeeEmpty: 'No hay anuncios para este evento.\n\n Si el anfitrion crea un anuncio, los invitados seran informados atravez de notificación.',
  },
  invites: {
    cancel: 'Cancelar',
    modalButton: 'Desinvitar',
    uninvite: 'Si la invitacion ya fue enviada, el usuario sera notificado. Quiere continuar?',
    removeConfirmText: 'Desinvita',
  },
  auth: {
    login: 'Iniciar Sesion',
    signup: 'Inscripción',
    timedOut: 'Código expirado, solicitar otro',
    getNewCode: 'Enviar nuevo código',
    verify: 'Verificar',
    sendLink: 'Enviar link',
    resetPassword: 'Crear Contraseña'
  },
  notifications: {
    empty: 'No hay notificaciones.',
    types: 'Las notificaciones que aparecerían aqui son invitaciones de eventos nuevos, y ajustes a los eventos invitados.',
    item: {
      notif_event_info_changes: 'ha actualizado',
      notif_event_cancellation: 'ha cancelado',
      notif_event_items_added: 'ha agregado articulos a',
      notif_event_announcement: 'Hay un nuevo anuncio para',
      accessRequest: 'Tiene un nuevo requisito de acceso para',
      accessGranted: 'Su requisito de acceso para el siguiente evento fue aceptada:',
      accessDenied: 'Su requisito de acceso para el siguiente evento fie rechazada:',
      accessRevoked: 'Su acceso al siguiente evento fue revocada:',
      pastEventRespond: 'Esta notificación fue un recordatorio para confirmar su asistencia a un evento pasado.',
      pastEventReminder: 'Esta notificación fue un recordatorio de un evento pasado:',
      dateConjecture: 'en',
      dontForget: 'No se olvide de agregar su RSVP a',
      reminder: 'Recordatorio:',
      startsIn: 'empieza',
      claimItems: 'Reclama artículos para llevar al evento.',
      updatedAttrs: {
        date_time: 'fecha',
        location: 'ubicación',
        details: 'detalles',
        changesIn: 'lo cambiado:',
      }
    },
  },
  empty404: 'Al parecer el recurso que intento acceder no existe.',
  empty401: 'No tiene autorización para ver ese recurso.',
  general: {
    remove: 'Remover',
    save: 'Guardar',
    name: 'nombre',
    email: 'email',
    goHome: 'Inicio',
    goBack: 'Atras',
    success: 'Éxito'
  },
  settings: {
    headers: {
      notifications: 'Notificaciones',
      general: 'General',
      dangerZone: 'Zona de Peligro',
    },
    notifs: {
      titles: {
        notif_event_cancellation: 'Evento cancelado',
        notif_event_info_changes: 'Cambios de información',
        notif_event_items_added: 'Articulos agregados',
        notif_event_reminders: 'Recordatorios de eventos',
        notif_event_respond: 'Recordatorios para responder',
        notif_event_announcement: 'Noticias nuevas',
        notif_event_access_request: 'Requisito de acceso',
        notif_event_access_request_granted: 'Acceso permitido',
        notif_event_access_request_denied: 'Acceso denegado',
        notif_event_access_revoked: 'Acceso revocado',
      },
      descriptions: {
        notif_event_cancellation: 'Notifiqueme cuando un evento al que yo he confirmado que si es cancelado',
        notif_event_info_changes: 'Notifiqueme cuando la informacion de un evento al cual confirmê que voy cambia. Incluyendo los detalles, la ubicaciòn, o la fecha/hora',
        notif_event_items_added: 'Notifiqueme cuando el anfitriòn de un evento al cual confirmé que voy agrega articulos',
        notif_event_reminders: 'Envíeme notificaciones recordatorias para eventos a los cuales voy a atender. Serán enviadas el día anterior y tres horas antes del comienzo del evento',
        notif_event_respond: 'Envíeme notificaciones recordatorias para responder a la invitación de eventos a los cuales no respondí el RSVP. Estas son enviadas el día anterior',
        notif_event_announcement: 'Notifiqueme cada vez que haya una noticia publicada en los eventos que estare atendiendo',
        notif_event_access_request: 'Notifiqueme cuando tengo un requisito de acceso a un evento',
        notif_event_access_request_granted: 'Notifiqueme cuando mi requisito de acceso fue permitido',
        notif_event_access_request_denied: 'Notifiqueme cuando mi requisito de acceso fue denegado',
        notif_event_access_revoked: 'Notifiqueme si mi acceso a un evento es revocado',
      },
    },
  },
  backendErrors: {
    overlappingEvent: 'No puede crear dos eventos a la misma hora',
    eventInPast: 'No puede crear eventos en el pasado',
    sessionMessageSend: 'No se pudo enviar el código',
    sessionOtpUpdate: 'No se pudo almacenar el código',
    sessionOtpMatch: 'El código no coincide',
    numberNotFound: 'Numero no encontrado',
    numberAlreadyExists: 'El numero pertence a un usuario',
    userNotFound: 'Usuario no encontrado',
    eventNotFound: 'Evento no encontrado',
    rsvpNotFound: 'RSVP no encontrado',
    itemNotfoun: 'Articulo no encontrado',
    eventItemNoRsvp: 'Debe aceptar la invitación antes de reclamar articulos',
    title: 'Asegure que su evento tenga titulo',
    unauthorized: 'No tiene autorización para ver ese recurso',
    rsvpUpdate: 'No se pudo actualizar la reserva',
    actionNotFound: 'Acción no encontrada',
    eventItemClaimed: 'El artículo ya fue reclamado',
    eventItemUpdateFail: 'No se pudo actualizar el estado del artículo',
    eventDeleted: 'Evento fue borrado',
    readNotifications: 'No se pudo actualizar las notificationes',
    tooManyOtpRequests: 'Demasiados requisitos por el código, espere 1 hora'
  },
  mobileOnly: {
    title: 'Solo móvil',
    explanation: 'Intentó acceder a una página que está diseñada para ser vista solo desde un dispositivo móvil.'
  },
  footer: {
    push1: '¿Quiere otificaciones automáticas? Obtenga la aplicación!',
    push2: 'Para crear su propio evento descargue la aplicación!',
    push3: '¡La aplicación ya está disponible para iPhone y Android! Descárgalo gratis ahora!',
    push4: 'Puede recrear eventos pasados, asi no tienes que agregar la misma lista de invitaciones y articulos.',
    push5: 'Pasen menos tiempo en sus teléfonos y más tiempo juntos. ¡Obtén la aplicación y haz que suceda!',
    push6: '¡Puedes hacer todo lo que haces en nuestro sitio, en la aplicación y más!',
    push7: 'Deshazte de los chats para organizar una reunión. ¡Ahorre tiempo y obtenga la aplicación!',
    push8: 'Estamos lanzando nuevas características a cada rato, obtenga la aplicación para acceder a los mas nuevo.',
    push9: '¡Obtenga la aplicación para ver eventos pasados, quién asistió y qué artículos solicitó el anfitrión!',
    push10: '¡Obtenga la aplicación para ver sus próximos eventos en una vista de calendario!',
    push11: '¿Quieres recibir notificaciones sobre eventos? ¡Obtener la aplicación!',
    push12: '¡Personaliza tus notificaciones desde la aplicación!',
    push13: '¿Tiene un evento semanal y dedica tiempo a confirmando invitados? Obtenga la aplicación para recrear eventos.',
    push14: '¡Actualice su información personal, así como su foto de perfil, a través de la aplicación!',
    availableOnStores: 'Disponible para Android y iPhone',
  }
}

export default spanish;
