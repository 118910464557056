const english = {
  screenTitles: {
    home: 'Home',
    calendar: 'Calendar',
    contact: 'Contact',
    profile: 'Profile',
    notifications: 'Notifications',
    settings: 'Settings',
    pastEvents: 'Past Events',
    hostedEvents: 'Hosted Events',
    upcomingEvents: 'Upcoming Events',
    eventShow: 'Event',
    eventNew: 'New Event',
    hostDetailsManage: 'Manage Details',
    hostAttendeesManage: 'Manage Attendees',
    hostItemsManage: 'Manage Items',
    attendeeDetails: 'Details',
    attendeeRsvpList: 'RSVPs',
    attendeeItems: 'Items',
    signup: 'Sign Up',
    login: 'Login',
    verify: 'Verify',
    resetPassword: 'Reset Password',
    forgotPassword: 'Forgot Password',
  },
  burgerNav: {
    events: 'Events',
    createEvent: 'Create Event',
    logout: 'Logout',
  },
  home: {
    upcomingEvents: 'Upcoming Events',
    itemsForEvent: 'Items for',
    registryItems: 'Registry Items Contributed',
    pastEvents: 'Past Events',
    createdEvents: 'Created Events',
    futureEvents: 'Future Events',
    event: 'event',
    date: 'date',
    rsvp: 'rsvp',
    item: 'item',
    owner: 'owner',
    noItems: 'This event doesn\'t have any items.',
    noEvents: 'There are no upcoming events! Create an event to get started.',
    calendarView: 'Calendar View',
    calendar: 'Calendar',
    eventsInvited: 'Events Invited',
    attending: 'Attended',
    rejected: 'Rejected',
    pending: 'Unanswered',
  },
  events: {
    noEvents: 'Looks like you don’t have any events yet. Events that show up here are those that you create or are invited to.',
    noPastEvents: 'Looks like there aren\'t any past events yet. Events that you\'ve been invited to or have hosted will show up in this list.',
    noUpcomingEvents: 'Looks like there aren\'t any upcoming events yet. Events you\'ve been invited to or created that haven\'t happened yet will show up in this list.',
    noHostedEvents: 'Looks like you haven\'t hosted (created) any events yet. Only events you\'ve hosted will show up in this list.\n\nFrom finished hosted events, you can re-create clone events. Cloned events include same details, items and invites, and every aspect of them can be edited.',
    noEventsOnDay: 'No events on this day!',
    noLocation: 'No specified location',
    noDetails: 'The host hasn\'t added any specifics',
    noRsvps: 'Looks like this event doesn\'t have any RSVPs.',
    createEvent: 'Create an event to get started.',
    create: 'Create Event',
    createDisabled: 'Cannot create event in the past',
    disabledDelete: 'Cannot delete a past event',
    disabledResponse: 'Cannot RSVP to past event',
    canceled: 'This event was canceled by host',
    hostedBy: 'Hosted by:',
    claim: 'claim',
    release: 'release',
    me: 'me',
    deleteEvent: 'Delete Event',
    confirm: 'Confirm',
    recreateEvent: 'Recreate Event',
    cannotEdit: 'You cannot update the event from web, please use the app instead',
    show: {
      details: 'Details',
      rsvp: 'RSVP List',
      items: 'Items',
      hostDetails: 'Update Details',
      hostRsvp: 'Invitations',
      hostItems: 'Manage Items',
      announcements: 'Announcements',
      change: 'Change',
      coming: 'Yes, I\'m coming!',
      notComing: 'No, I can\'t make it.',
      respond: 'Respond',
      addRsvp: 'Add your RSVP',
      cancel: 'Cancel',
      when: 'when',
      where: 'where',
      additional: 'additional',
    },
    recreate: {
      modal: {
        recreate: 'Recreate Event',
        items: 'Items',
        date: 'Event Date',
        invites: 'Invites',
        cancel: 'Cancel',
        button: 'Recreate'
      },
      items: 'All items will be copied to the new event, and can be updated from \'Manage Items\' page',
      details: 'Recreated event details can be adjusted via the \'Manage Details\' page, including the event date and time',
      invites: 'Invitations will be unsent until you send them from \'Manage Attendees\' page',
      disabled: 'Cannot re-create a future event'
    },
    form: {
      titleMissing: 'Title must be present',
      titlePlaceholder: 'Pizza Night',
      title: 'Title',
      date: 'Date',
      time: 'Time',
      locationLabel: 'Location',
      detailsPlaceholder: 'Add details for your event. Example: Please park at the end of the street',
      detailsLabel: 'Details',
      formEmpty: 'Form empty',
      noChanges: 'No change to save',
      create: 'Create',
      update: 'Update'
    },
    invites: {
      sent: 'Sent',
      invites: 'Invites',
      sendInvites: 'Send Invites'
    },
  },
  items: {
    addItemPlaceholder: 'Add an item...',
    noItems: 'Looks like this event doesn\'t have any items.',
    addItems: 'Add items so your attendees can collaborate and bring stuff to your event.',
    awaitItems: 'Check back here later to see if the event host added items for you to chose from and bring to the event!\n\nYou can toggle notifications for when items are added to an event from the Settings page.',
  },
  announcements: {
    attendeeEmpty: 'There are no announcements in this event.\n\n If the host makes announcements they will be sent out through push notifications only.',
  },
  invites: {
    cancel: 'Cancel',
    modalButton: 'Uninvite',
    uninvite: 'The invitee will receive notice of being uninvited. Are you sure you want to continue?',
    removeConfirmText: 'Uninvite',
  },
  auth: {
    login: 'Login',
    signup: 'Sign up',
    timedOut: 'Code expired, request another',
    getNewCode: 'Send new code',
    verify: 'Verify',
    sendLink: 'Send Link',
    resetPassword: 'Reset Password'
  },
  notifications: {
    empty: 'There are no notifications to display.',
    types: 'Notifications that would show up here include event invitations and changes.',
    item: {
      notif_event_info_changes: 'has updated',
      notif_event_cancellation: 'has canceled',
      notif_event_items_added: 'added items to',
      notif_event_announcement: 'There is a new announcement for',
      accessRequest: 'You have a new access request for',
      accessGranted: 'Your access request to the following event was granted:',
      accessDenied: 'Your access request to the following event was denied::',
      accessRevoked: 'Your access to the following event was revoked:',
      pastEventRespond: 'This notification was a reminder to RSVP to a past event:',
      pastEventReminder: 'This notification was a reminder for a past event:',
      invited: 'has invited you to',
      updated: 'has updated',
      deleted: 'has canceled',
      dateConjecture: 'on',
      dontForget: 'Don\'t forget to RSVP to',
      reminder: 'Reminder:',
      startsIn: 'starts',
      claimItems: 'Claim items to bring to the event.',
      updatedAttrs: {
        date_time: 'date/time',
        location: 'location',
        details: 'details',
        changesIn: 'changes to:',
      }
    },
  },
  empty404: 'Looks like the resource you tried to access isn\'t there.',
  empty401: 'Looks like you\'re not authorized to view the resource requested.',
  general: {
    remove: 'Remove',
    save: 'Save',
    name: 'name',
    email: 'email',
    goHome: 'Go home',
    goBack: 'Go back',
    success: 'Success'
  },
  settings: {
    headers: {
      notifications: 'Notifications',
      general: 'General',
      dangerZone: 'Danger Zone'
    },
    notifs: {
      titles: {
        notif_event_cancellation: 'Event cancellations',
        notif_event_info_changes: 'Event info changes',
        notif_event_items_added: 'Event items added',
        notif_event_reminders: 'Event reminders',
        notif_event_respond: 'Reminder to respond',
        notif_event_announcement: 'Event announcements',
        notif_event_access_request: 'Access requests',
        notif_event_access_request_granted: 'Access granted',
        notif_event_access_request_denied: 'Access denied',
        notif_event_access_revoked: 'Access revoked',
      },
      descriptions: {
        notif_event_cancellation: 'Notify me when an event that I\'m attending is canceled',
        notif_event_info_changes: 'Notify me when the main info for an event I\'m attending changes. This includes date or time, location and details',
        notif_event_items_added: 'Notify me when the host of an event I\'m attending adds items for attendees to bring',
        notif_event_reminders: 'Send me a reminder notification for events I\'m attending. We send these the day before and three hours before the event start time',
        notif_event_respond: 'Send me a reminder notification to respond to events I haven\'t RSVPd to. These are sent out the day before the event',
        notif_event_announcement: 'Notify me when there is a new announcement on an event I\'m attending',
        notif_event_access_request: 'Notify me when I have a new event access request',
        notif_event_access_request_granted: 'Notify me when an access request has been granted',
        notif_event_access_request_denied: 'Notify me when an access request has been denied',
        notif_event_access_revoked: 'Notify me when access to an event has been revoked',
      },
    }
  },
  backendErrors: {
    overlappingEvent: 'Cannot create two events with the same date and time',
    eventInPast: 'Cannot create an event in the past',
    sessionMessageSend: 'Failed to send the one time password',
    sessionOtpUpdate: 'Failed to set the one time password',
    sessionOtpMatch: 'Code does not match',
    numberNotFound: 'Number not found',
    numberAlreadyExists: 'Number already associated with a user',
    userNotFound: 'User not found',
    eventNotFound: 'Event not found',
    rsvpNotFound: 'RSVP not found',
    itemNotfoun: 'Item not found',
    eventItemNoRsvp: 'Must accept RSVP before claiming items',
    title: 'Ensure there is a title present',
    unauthorized: 'Not authorized',
    rsvpUpdate: 'Couldn\'t udpate RSVP',
    actionNotFound: 'Action not found',
    eventItemClaimed: 'Item was already claimed',
    eventItemUpdateFail: 'Couldn\'t update the item status',
    eventDeleted: 'Event was deleted',
    readNotifications: 'Couldn\'t "read" notifications',
    tooManyOtpRequests: 'Too many requests for one-time-passcode, wait 1 hour',
  },
  mobileOnly: {
    title: 'Mobile only',
    explanation: 'You attempted to access a page that is designed to be viewed from mobile devices only.'
  },
  footer: {
    push1: 'Stay informed about event changes with push notifications. Get the app!',
    push2: 'Want to create events, invite your contacts, and add items for them to bring to the event? Download the app!',
    push3: 'The app is now available for iOS and Android! Download it for free now!',
    push4: 'With the app you can \'recreate\' past events, so you don\'t have to add the same list of invites and items!',
    push5: 'Spend less time on your phones, and more time together. Get the app, and make it happen!',
    push6: 'You can do everything you do on our site, on the app, and more!',
    push7: 'Get rid of chat threads in order to organize a gathering. Save yourself some time and get the app!',
    push8: 'We\'re coming out with new features all the time, get the app to see what the latest and greatest are.',
    push9: 'Get the app to view past events, who attended, and what items were requested by the host!',
    push10: 'Get the app to see your upcoming events in a calendar view!',
    push11: 'Wanna get notified about events? Get the app!',
    push12: 'Customize your push notifications from the app!',
    push13: 'Recreate from past events to avoid confirming attendance to recurring events.',
    push14: 'Update your personal information as well as your profile picture, via the app!',
    availableOnStores: 'Available for Android and iPhone',
  }
}

export default english;
