import React from "react";
import { StyleSheet, View, Modal } from "react-native";

import styleGuide from "../assets/styleGuide";
import Flash from "./Flash";
const { colors, distance } = styleGuide;

export default function ModalComp({ children, visible, flashErrs, flashExit }) {
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={visible}
    >
      {
        flashErrs && (
          <View style={styles.errWrap}>
            <Flash messages={flashErrs} onExit={flashExit}/>
          </View>
        )
      }
      <View style={styles.contentWrapper}>
        {children}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  contentWrapper: {
    position: 'absolute',
    top: '20%',
    alignSelf: 'center',
    paddingTop: distance.large,
    paddingBottom: distance.large,
    paddingLeft: distance.xlarge,
    paddingRight: distance.xlarge,
    backgroundColor: colors.white,
    borderRadius: distance.small,
    shadowColor: 'black',
    width: '80%',
  },
  errWrap: {
    marginTop: distance.xlarge * 2,
    padding: distance.xlarge,
    alignItems: 'center'
  }
});
