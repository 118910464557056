import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, FlatList, ActivityIndicator } from 'react-native-web';
import { useNavigate, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import Icon from '../../../assets/icons/base';
import Button from '../../Button';
import ModalComp from '../../Modal';

import { getEvent, respondToInvite } from '../../../utils/api/events';
import { SET_EVENT_ID } from '../../../reducers/HostAttendeesManage';

import { rsvpIcon } from '../../../helpers/rsvp_icon';
import { eventDateIsFuture, getHourAndMinute, getMonthAndDate } from '../../../helpers/date';
import languageSpecificText from '../../../helpers/languages';

import styleGuide from '../../../assets/styleGuide';
import RequestAccess from './RequestAccess';
const { colors, fontSize, distance } = styleGuide;

export default function Body() {
  const { id: eventId } = useParams();

  const [event, setEvent] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch({ type: SET_EVENT_ID, value: eventId });
      const res = await getEvent(eventId);

      if (res.errors) {
        if (res.errors[0] === 'eventNotFound') {
          navigate('/resource-not-found')
          return null;
        } else if (res.errors[0] === 'unauthorized') {
          navigate('/unauthorized')
          return null;
        }
      } else {
        setEvent(res.event);
      }
    })()
  }, [eventId, dispatch, navigate])


  if (!event) return <ActivityIndicator size="large" style={{ marginTop: styleGuide.distance.large }} />;

  const linksTextPropsMap = [
    { iconFile: 'details',    text: languageSpecificText('events', 'show', 'details'),       path: `/events/${eventId}/details` },
    { iconFile: 'users',      text: languageSpecificText('events', 'show', 'rsvp'),          path: `/events/${eventId}/invites` },
    { iconFile: 'empty_cart', text: languageSpecificText('events', 'show', 'items'),         path: `/events/${eventId}/items` },
    { iconFile: 'megaphone',  text: languageSpecificText('events', 'show', 'announcements'), path: `/events/${eventId}/announcements` },
  ];

  const renderLink = (obj) => {
    const { iconFile, path, text } = obj.item;
    return (
      <TouchableOpacity
        style={styles.liWrapper}
        onPress={() => navigate(path)}
      >
        <View style={styles.li}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={styles.iconWrapper}>
              <Icon fileName={iconFile} />
            </View>
            <Text style={{ fontSize: fontSize.subTitle }}>{text}</Text>
          </View>
          <Icon fileName="chevron_right" />
        </View>
      </TouchableOpacity>
    )
  }

  const hasAccess = event.currUserIsCreatorOrHost || (event.rsvp && event.rsvp.access !== 'requested');

  if (!hasAccess) {
    return <RequestAccess event={event} />
  }

  return (
    <View style={{ alignItems: 'center', flex: 1 }}>
      <View style={{ width: '100%', justifyContent: 'space-between' }}>
        <Text style={styles.title}>{event.title}</Text>
        {
          !event.currUserIsCreatorOrHost && (
            <View style={{ flexDirection: 'row', marginLeft: distance.medium, marginBottom: distance.medium }}>
              <Text style={{ fontSize: fontSize.small, color: colors.gray.light }}>Hosted by:</Text>
              <Text
                style={{ marginLeft: distance.small, marginRight: distance.large, textDecorationLine: 'underline' }}
                onPress={() => navigate(`/profile/${event.creator.id}`)}
              >
                {event.creator.name}
              </Text>
            </View>
          )
        }
        <FlatList
          data={linksTextPropsMap}
          renderItem={renderLink}
          keyExtractor={obj => obj.iconFile}
          ListHeaderComponent={
            event && event.currUserIsCreatorOrHost
              ? <HostActions />
              : <AttendeeActions event={event} />
          }
        />
      </View>
    </View>
  )
}

const HostActions = () => {
  return (
    <View style={{ width: '100%', alignItems: 'center', backgroundColor: colors.gray.lighter, padding: distance.medium }}>
      <Text>{languageSpecificText('events', 'cannotEdit')}</Text>
    </View>
  )
}

const AttendeeActions = ({ event }) => {
  const [showRsvpModal, setShowRsvpModal] = useState(false);
  const [responseRsvpStatus, setResponseRsvpStatus] = useState(null);

  const finishModal = async (response = false) => {
    if (response) {
      const res = await respondToInvite(event.rsvp.id, response);
      if (res.success) setResponseRsvpStatus(response);
    }
    setShowRsvpModal(false);
  }

  const responseIcon = rsvpIcon(responseRsvpStatus || event.rsvp.response);

  let text;
  let buttonText = languageSpecificText('events', 'show', 'change');
  let buttonBackgroundColor = colors.gray.darker;

  if ((responseRsvpStatus || event.rsvp.response) === "attending") text = languageSpecificText('events', 'show', 'coming');
  if ((responseRsvpStatus || event.rsvp.response) === "rejected") text = languageSpecificText('events', 'show', 'notComing');
  if ((responseRsvpStatus || event.rsvp.response) === "pending") {
    text = languageSpecificText('events', 'show', 'addRsvp');
    buttonText = languageSpecificText('events', 'show', 'respond');
    buttonBackgroundColor = colors.green;
  }

  let dateTimeDisplay = '';
  dateTimeDisplay += getMonthAndDate(event.dateTime);
  dateTimeDisplay += ' @ ';
  dateTimeDisplay += getHourAndMinute(event.dateTime);

  return (
    <View style={styles.attendeeActions}>
      <ModalComp visible={showRsvpModal}>
        <Text style={{ fontSize: fontSize.title }}>{event.title}</Text>
        <Text style={{ fontSize: fontSize.subTitle, color: colors.gray.darkest }}>{event.location}</Text>
        <Text style={{ fontSize: fontSize.subTitle, color: colors.gray.darkest }}>{dateTimeDisplay}</Text>
        <Button async title={languageSpecificText('events', 'show', 'coming')} addedStyles={{ backgroundColor: colors.green }} onPress={() => finishModal("attending")} />
        <Button async title={languageSpecificText('events', 'show', 'notComing')} addedStyles={{ backgroundColor: colors.red.light }} onPress={() => finishModal("rejected")} />
        <Button async title={languageSpecificText('events', 'show', 'cancel')} addedStyles={{ backgroundColor: colors.gray.darker }} onPress={() => finishModal()} />
      </ModalComp>

      <View style={{ width: '100%', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between' }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {responseIcon}
          <Text style={{ marginLeft: distance.large }}>{text}</Text>
        </View>

        <Button
          onPress={() => setShowRsvpModal(true)}
          disabled={!eventDateIsFuture(event.dateTime)}
          disabledText={languageSpecificText('events', 'disabledResponse')}
          title={buttonText}
          addedStyles={{ backgroundColor: buttonBackgroundColor, width: 100, marginTop: 0 }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  liWrapper: {
    padding: distance.medium,
    borderBottomColor: colors.gray.light,
    borderBottomWidth: 1,
  },
  li: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  iconWrapper: {
    height: 60,
    width: 60,
    backgroundColor: colors.gray.lighter,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: distance.large
  },
  title: {
    fontSize: fontSize.subTitle,
    padding: distance.medium,
    paddingBottom: distance.small,
    fontWeight: 'bold'
  },
  attendeeActions: {
    width: '100%',
    padding: distance.large,
    alignItems: 'center',
    backgroundColor: colors.gray.lightest,
  }
});
