import { storeAuth, clearStoredAuth, getStoredAuth } from "../storage";
import { makeAuthRequest } from "./auth";

export const signup = async (params) => {
  const { name, email, password } = params;
  const endpoint = process.env.REACT_APP_API_HTTP_ENDPOINT;

  let res = await fetch(`${endpoint}/users`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: { name, email, password } })
  });

  res = await res.json();

  if (res.user) {
    storeAuth(res.user);
    return res.user;
  }

  return { errors: res.message };
}

export const login = async (params) => {
  const { email, password } = params;
  const endpoint = process.env.REACT_APP_API_HTTP_ENDPOINT;

  let res = await fetch(`${endpoint}/sessions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: { email, password } })
  });

  res = await res.json();

  if (res.user) {
    storeAuth(res.user);
    return res.user;
  }

  return { errors: res.errors };
}

export const sendResetPasswordLink = async (email) => {
  const endpoint = process.env.REACT_APP_API_HTTP_ENDPOINT;
  let res = await fetch(`${endpoint}/send_reset_password_email`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email })
  });

  res = await res.json();

  return res;
}

export const resetPassword = async ({ email, token, password }) => {
  const endpoint = process.env.REACT_APP_API_HTTP_ENDPOINT;
  let res = await fetch(`${endpoint}/reset_password`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, resetPasswordToken: token, password })
  });

  res = await res.json();

  return res;
}

export const logout = async () => {
  const res = await makeAuthRequest('/logout', 'PATCH')
  clearStoredAuth();
  return res;
}

export const getUser = async (userId) => {
  return await makeAuthRequest(`/users/${userId}`, 'GET')
}

export const updateUserSettings = async (settings) => {
  const storedAuth = getStoredAuth();
  const { id } = storedAuth;

  return await makeAuthRequest(`/users/${id}`, 'PUT', { settings })
}

export const updateUser = async (user) => {
  const storedAuth = getStoredAuth();
  const { id } = storedAuth;

  return await makeAuthRequest(`/users/${id}`, 'PUT', { user })
}

export const getNotifications = async () => {
  const storedAuth = getStoredAuth();
  const { id } = storedAuth;

  return await makeAuthRequest(`/users/${id}/notifications`, 'GET')
}

export const readNotifications = async (ids) => {
  const storedAuth = getStoredAuth();
  const { id } = storedAuth;

  return await makeAuthRequest(`/users/${id}/notifications/read`, 'PATCH', { ids });
}

export const getDashboardData = async () => {
  return await makeAuthRequest('/dashboard_data', 'GET');
}

export const sendVerifyEmail = async () => {
  return await makeAuthRequest('/request_verify_token', 'POST');
}

export const verifyEmail = async (token) => {
  return await makeAuthRequest('/verify_email', 'POST', { emailVerifyToken: token });
}
