import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, TouchableOpacity, Text, ActivityIndicator } from 'react-native-web';
import { Tooltip } from 'react-tooltip';
import "../../node_modules/react-tooltip/dist/react-tooltip.css";

import styleGuide from '../assets/styleGuide';
const { distance, colors, fontSize } = styleGuide;

export default function Button({
  title,
  disabled,
  disabledText,
  onPress,
  addedStyles = {},
  addedTextStyles = {},
  tooltipPlacement="bottom",
  async = false,
  hollow = false, // boolean whether button should have border and no bg color
  borderColor = colors.blue.light, // only applied to hollow buttons
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [performingAsync, setPerformingAsync] = useState(false);
  const mounted = useRef(false);
  const btnRef = useRef();
  const randomId = `${Math.random()}`

  const stylesForButton = () => {
    return {
      ...styles.button,
      ...addedStyles,
      ...(disabled ? styles.disabled : {}),
      ...(hollow ? styles.hollow(borderColor) : {})
    }
  }

  const handlePress = async () => {
    if (disabled && disabledText) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }

    if (!disabled) {
      if (async) {
        setPerformingAsync(true);
        btnRef.current.performingAsync = true;
        await onPress();
        setTimeout(() => {
          if (mounted.current) {
            setPerformingAsync(false);
            btnRef.current.performingAsync = false;
          }
        }, 500)
      } else {
        onPress();
      }
    }
  }

  useEffect(() => {
    mounted.current = true;
    btnRef.current.id = randomId;
    const currentBtnRef = btnRef.current;

    return () => {
      mounted.current = false;
      if (currentBtnRef) currentBtnRef.performingAsync = false;
      setPerformingAsync(false);
    }
  }, [randomId])

  return (
    <TouchableOpacity
      ref={btnRef}
      style={stylesForButton()}
      onPress={handlePress}
      activeOpacity={disabled ? 1.0 : 0.7}
      disabled={btnRef.current?.performingAsync || disabled}
    >

      {
        btnRef.current?.performingAsync || performingAsync
          ? <ActivityIndicator size="small" />
          : <Text style={{ ...styles.text(hollow), ...addedTextStyles }}>{title}</Text>
      }

      <Tooltip
        content={disabledText}
        isOpen={showTooltip}
        place={tooltipPlacement}
        anchorId={randomId}
      />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    marginTop: distance.large,
    height: 50,
    width: '100%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    shadowColor: '#171717',
    shadowOffset: { width: -2, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  text: (hollow) => ({
    color: hollow ? colors.blue.light : colors.white,
    fontSize: fontSize.large
  }),
  disabled: {
    backgroundColor: colors.gray.light
  },
  hollow: (color) => ({
    backgroundColor: 'transparent',
    borderWidth: 2,
    borderColor: color,
  })
});
