export const Features = () => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-8 col-md-offset-2 section-title'>
            <h2>How it Works</h2>
          </div>
        </div>
        <div className='row step-row'>
          <div className='col-xs-12 col-md-4'>
            <div className="step-row-inner">
              <div className="step-row-info">
                <div className="work-step">1</div>
                <h3>Creating an Event</h3>
                <p>Create an event to get started! List all the necessary information, like location and time. Include any specific details you want your invites to be aware of.</p>
              </div>
              <div className="step-row-img"><img src="img/create_event.png" className="img-responsive img-2" alt=""/></div>
            </div>
          </div>
          <div className='col-xs-12 col-md-4'>
            <div className="step-row-inner">
              <div className="step-row-info">
                <div className="work-step">2</div>
                <h3>Add Items</h3>
                <p>Potluck style. Add items for your guests to contribute with. They will be able to view the list you add, and select what they want to bring.</p>
              </div>
              <div className="step-row-img"><img src="img/manage_items.png" className="img-responsive img-2" alt="" /></div>
            </div>
          </div>
          <div className='col-xs-12 col-md-4'>
            <div className="step-row-inner">
              <div className="step-row-info">
                <div className="work-step">3</div>
                <h3>Sending Invitations</h3>
                <p>Give GathrUp access to your contacts to start adding invites. We don't store your contacts on our servers, so no need to worry about data misusage.</p>
              </div>
              <div className="step-row-img"><img src="img/manage_attendees.png" className="img-responsive img-2" alt="" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const data = {
//   eventCreate: {
//     title: 'Event Creation',
//     subHeader: 'Create an event that you can then invite your contacts to',
//     1: { title: 'From your dashboard', text: 'From your dashboard click on Create Event', img: '' },
//     2: { title: 'From navigation menu', text: 'From the navigation menu click on Create Event', img: '' },
//     3: { title: 'Create the event', text: 'Fill in the details of your event, and click save. Don\'t worry, you can update the details later', img: '' }
//   },
//   eventItems: {
//     title: 'Event Items',
//     subHeader: 'Add items to your events so that your invites can collaborate and bring stuff',
//     1: { title: 'Add items to your event', text: 'This is a great way to let your guests contribute to the event by bringing items the event needs', img: '' },
//     2: { title: 'Managing items', text: 'We can remove or add items up until the start of the event. A host can remove an item even if someone already claimed it', img: '' },
//   },
//   eventRsvps: {
//     title: 'Adding RSVPs',
//     subHeader: 'Give GathrUp access to your contacts and send invitations',
//     1: {title: 'Allow access to contacts', text: 'Allow GathrUp access to contacts. We don\'t store them in our servers, so you can rest assured the data isn\'t being misused', img: '' },
//     2: {title: 'Build the list', text: 'Accumulate your list of invites. We do not send the invites in this step, so you can visualize the list before actually sending invites', img: '' },
//     3: {title: 'Revise list and send invitations', text: 'Here is where we make sure the RSVP list is looking good before we send out the invitations. You can remove people from the list, or go back to Contacts to continue adding', img: '' },
//     4: {title: 'Managing invitations', text: 'Even after sending invites you can \'uninvite\' someone. If the invite message was already sent out and they RSVPd, they\'ll receive a notification letting them know they\'ve been uninvited', img: '' },
//     5: {title: 'Send more invites', text: 'After invites are sent, we can keep adding people to the event in the same fashion as we\ve done with the first batch', img: '' },
//   },
//   eventRecreate: {
//     title: 'Event recreation',
//     subHeader: 'Save time by cloning from a past event',
//     1: {title: 'Recreate an event', text: 'This feature is very useful if you have an event that looks very similar to a past event', img: '' },
//     2: {title: 'Understanding event recreation', text: 'When you recreate from a past event all the items and invites will be ported over to the new one. Invites won\'t be sent out until you manually send them, so you can tweak the list. Likewise, the event date and time should be adjusted', img: '' },
//   },
// }

// The data above is to eventually create a nicer Features section. With the feature types on the left, and a carousel going through the steps on the right
