import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native-web";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { storeAuth } from '../utils/storage';
import { SET_CURRENT_USER } from "../reducers/Auth";
import { SET_FLASH } from "../reducers/FlashReducer";
import { getUser, sendVerifyEmail, verifyEmail } from "../utils/api/users";

import styleGuide from "../assets/styleGuide";
import Icon from "../assets/icons/base";
import Button from "../components/Button";
import BaseScreen from "../components/screen_specific/BaseScreen";
const { distance, colors, fontSize } = styleGuide;

function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useSelector(state => state.auth);

  const [verifyEmailSent, setVerifyEmailSent] = useState(false);
  const [verifyToken, setVerifyToken] = useState('');

  useEffect(() => {
    (async () => {
      const res = await getUser(currentUser.id);
      const { email, emailVerified, imgUrl, name, id, emailVerifiedAt } = res.user;

      // Resetting the current user here in case there were changes that need to be shown
      const value = { email, emailVerified, emailVerifiedAt, imgUrl, name, id };
      dispatch({ type: SET_CURRENT_USER, value });
    })()
  }, [currentUser.id, dispatch])

  const handleSendToken = async () => {
    const res = await sendVerifyEmail();
    dispatch({ type: SET_FLASH, messages: [res.message], isSuccess: res.success });
    if (res.success) {
      setVerifyEmailSent(true);
    }
  }

  const handleVerifyToken = async () => {
    const res = await verifyEmail(verifyToken);
    if (res.user) {
      const { email, emailVerified, imgUrl, name, id, emailVerifiedAt } = res.user;
      const value = { email, emailVerified, emailVerifiedAt, imgUrl, name, id };
      storeAuth({ ...res.user, stored_at: Date.now() });
      dispatch({ type: SET_CURRENT_USER, value });
      dispatch({ type: SET_FLASH, messages: ["Successfully verified email"], isSuccess: true });
      navigate("/settings")
    } else {
      dispatch({ type: SET_FLASH, messages: res.messages, isSuccess: false });
    }
  }

  return (
    <View style={styles.container}>
      <Text style={{ color: colors.gray.darkest, fontSize: fontSize.large, marginBottom: distance.large }}>
        Before you can attend or host your own events your email must be verified
      </Text>

      <View>
        <View style={styles.emailStatus}>
          <View style={styles.spaceBetween}>
            <Text style={{ fontSize: fontSize.large }}>{currentUser.email}</Text>
            <Icon fileName={currentUser.emailVerified ? 'circle_green_check' : 'circle_red_x'} />
          </View>
        </View>

        {
          !currentUser.emailVerified && !verifyEmailSent && (
            <View style={styles.actionSection}>
              <Button
                async
                hollow
                title="Send Verify Email"
                onPress={handleSendToken}
              />
            </View>
          )
        }

        {
          !currentUser.emailVerified && verifyEmailSent && (
            <View style={{ marginTop: distance.xlarge }}>
              <TextInput
                style={styles.verifyTokenInput}
                onChangeText={setVerifyToken}
                value={verifyToken}
                placeholder="************"
                secureTextEntry={true}
                autoFocus={true}
              />
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                  hollow
                  async
                  title="Resend Email"
                  addedStyles={{ width: '48%' }}
                  onPress={handleSendToken}

                />
                <Button
                  async
                  title="Verify"
                  disabled={verifyToken.length === 0}
                  disabledText="Add token from email"
                  addedStyles={{ backgroundColor: colors.green, width: '48%' }}
                  onPress={handleVerifyToken}
                />
              </View>
            </View>
          )
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: distance.xlarge
  },
  emailStatus: {
    borderWidth: 1,
    borderRadius: 3,
    borderColor: colors.gray.light,
    padding: distance.medium
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionSection: {
    marginTop: distance.large
  },
  verifyTokenInput: {
    width: '100%',
    alignSelf: 'center',
    padding: distance.small,
    textAlign: 'center',
    backgroundColor: colors.gray.lightest,
    borderRadius: 5,
  }
});

export default function VerifyEmailScreen() {
  return <BaseScreen component={VerifyEmail} scrollView={false} />
}
