import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { StyleSheet, View, Text, ActivityIndicator } from 'react-native-web';

import LineItem from './LineItem';

import { getHourAndMinute, getMonthAndDate } from '../../../helpers/date';
import { getEvent } from '../../../utils/api/events';
import languageSpecificText from '../../../helpers/languages';
import styleGuide from '../../../assets/styleGuide';
const { colors, distance, fontSize } = styleGuide;

export default function AttendeeDetails() {
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const [event, setEvent] = useState({});


  useEffect(() => {
    (async () => {
      const res = await getEvent(eventId);
      if (res.errors) {
        if (res.errors[0] === 'eventNotFound') {
          navigate('/resource-not-found')
          return null;
        } else if (res.errors[0] === 'unauthorized') {
          navigate('/unauthorized')
          return null;
        }
      }


      setEvent(res.event);
    })()
  }, [eventId, navigate]);

  if (!event.status) {
    return <ActivityIndicator size="large" style={{ marginTop: distance.large }} />;
  }

  return (
    <View>
      {
        event.status === 'canceled' && (
          <LineItem addedStyles={{ backgroundColor: colors.red.light, justifyContent: 'center' }}>
            <Text style={{ color: colors.white, fontWeight: 'bold' }}>{languageSpecificText('events', 'canceled')}</Text>
          </LineItem>
        )
      }
      <View style={{ ...styles.lineItem, borderBottomColor: colors.gray.darker, borderBottomWidth: 1 }}>
        <Text style={{ ...styles.wrap, fontSize: fontSize.title, alignSelf: 'center' }}>{event.title}</Text>
      </View>

      <View style={styles.lineItem}>
        <Text style={styles.label}>{languageSpecificText('events', 'show', 'when').toUpperCase()}</Text>
        <Text style={styles.wrap}>{`${getMonthAndDate(event.dateTime)} @ ${getHourAndMinute(event.dateTime)}`}</Text>
      </View>

      <View style={styles.lineItem}>
        <Text style={styles.label}>{languageSpecificText('events', 'show', 'where').toUpperCase()}</Text>

        {
          event.location && event.location !== ''
            ? <Text style={styles.wrap}>{event.location}</Text>
            : <Text style={{ ...styles.wrap, color: colors.gray.darkest, fontSize: fontSize.medium }}>
              {languageSpecificText('events', 'noLocation')}
            </Text>
        }
      </View>

      <View style={styles.lineItem}>
        <Text style={styles.label}>{languageSpecificText('events', 'show', 'additional').toUpperCase()}</Text>
        {
          event.details
            ? <Text style={styles.wrap}>{event.details}</Text>
            : <Text style={{ ...styles.wrap, color: colors.gray.darkest, fontSize: fontSize.medium }}>
              {languageSpecificText('events', 'noDetails')}
            </Text>
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  iconWrapper: {
    height: 50,
    width: 50,
    backgroundColor: colors.gray.lighter,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: distance.large
  },
  wrap: {
    flex: 1,
    flexWrap: 'wrap',
    fontSize: fontSize.subTitle
  },
  label: {
    fontSize: fontSize.small,
    color: colors.gray.darker,
  },
  lineItem: {
    padding: distance.large
  }
});
