import React from 'react';
import { useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native-web';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icon from '../assets/icons/base';

import styleGuide from '../assets/styleGuide';
import languageSpecificText from '../helpers/languages';
import { STORE_PATH } from '../reducers/Auth';
const { distance, colors } = styleGuide;

export default function Empty({ texts, iconFile, goBack = false, showLink = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storedPath } = useSelector(state => state.auth);

  useEffect(() => {
    if (storedPath) {
      const path = storedPath;
      dispatch({ type: STORE_PATH, value: null });
      navigate(path);
    }
  }, [storedPath, dispatch, navigate]);

  return (
    <View style={styles.container}>
      <Icon fileName={iconFile} />
      <View style={{ marginTop: distance.xlarge }}>
        {
          texts.map((text, idx) => {
            const newLine = idx === text.length - 1 ? '' : '\n';
            return <Text style={styles.text} key={idx}>{`${text}${newLine}`}</Text>
          })
        }

        {
          showLink &&
            <Text
              onPress={() => navigate(goBack ? -1 : "/")}
              style={{ alignSelf: 'center', marginTop: distance.large, color: colors.blue.light, textDecorationLine: 'underline' }}
            >
              {languageSpecificText('general', goBack ? 'goBack' : 'goHome')}
            </Text>
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.gray.lightest,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    padding: distance.xlarge,
    margin: distance.large
  },
  text: {
    textAlign: 'center',
    color: colors.gray.darkest
  }
});
