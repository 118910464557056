const globalStyles = {
  colors: {
    white: "#fff",
    teal: "#008080",
    green: "#23C215",
    yellow: '#e2ff0a',
    red: {
      light: "#FF0000",
      dark: "#8f0a00",
    },
    blue: {
      light: '#3e81ed',
      lighter: '#96b6ff',
    },
    gray: {
      lightest: "#EBEEED",
      lighter: "#E2E6E5",
      light: "#D1D7D6",
      darker: "#B2BCBA",
      darkest: "#94A29F"
    },
  },
  fontSize: {
    title: 26,
    subTitle: 20,
    large: 18,
    medium: 16,
    small: 14,
    xsmall: 12,
    xxsmall: 10
  },
  distance: {
    xsmall: 5,
    small: 10,
    medium: 15,
    large: 20,
    xlarge: 30
  },
  buttons: {
    round: {
      large: {
        height: 50,
        width: 50,
        borderRadius: 25
      },
      regular: {
        height: 40,
        width: 40,
        borderRadius: 20
      },
      small: {
        height: 30,
        width: 30,
        borderRadius: 15
      },
      xsmall: {
        height: 20,
        width: 20,
        borderRadius: 10
      }
    }
  }
}

export default globalStyles;
