export const SET_FLASH = 'SET_FLASH';
export const CLEAR_FLASH = 'CLEAR_FLASH';

const _defaultState = {
  messages: null,
  isSuccess: null
};

export default function FlashReducer(state = _defaultState, action) {
  const dupState = Object.assign({}, state);

  switch (action.type) {
    case SET_FLASH:
      dupState.messages = action.messages;
      dupState.isSuccess = action.isSuccess;
      return dupState;
    case CLEAR_FLASH:
      return _defaultState;
    default:
      return state;
  }
}
