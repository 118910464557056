import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    emailjs.send(
      process.env.REACT_APP_API_MAILJS_SERVICE_ID,
      process.env.REACT_APP_API_MAILJS_TEMPLATE_ID,
      {
        from_name: name,
        message: message,
        reply_to: email,
      },
      process.env.REACT_APP_API_MAILJS_PUBLIC_KEY
    )
      .then(
        (result) => {
          if (result.text === 'OK') {
            setIsSubmitted(true);
          }
          clearState();
        },
        (error) => {}
      );
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='section-title text-center'>
                {isSubmitted ? (<h2>Thank you</h2>) : (<h2>We’d love to hear from you</h2>)}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 text-center'>
                {isSubmitted ? (<p>We’ll get back to you shortly.</p>) : (
                  <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Name</label>
                          <input
                            type='text'
                            id='name'
                            name='name'
                            className='form-control'
                            required
                            onChange={handleChange}
                          />
                          <p className='help-block text-danger'></p>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-group'>
                          <label>Email</label>
                          <input
                            type='email'
                            id='email'
                            name='email'
                            className='form-control'
                            required
                            onChange={handleChange}
                          />
                          <p className='help-block text-danger'></p>
                        </div>
                      </div>
                    </div>
                    <div className='form-group mt-30'>
                      <label>Message</label>
                      <textarea
                        name='message'
                        id='message'
                        className='form-control'
                        rows='6'
                        required
                        onChange={handleChange}
                      ></textarea>
                      <p className='help-block text-danger'></p>
                    </div>
                    <div id='success'></div>
                    <button type='submit' className='btn btn-custom btn-lg'>
                      Send
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
