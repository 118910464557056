import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { StyleSheet, ScrollView, View } from 'react-native-web';

import Button from '../components/Button';
import Flash from '../components/Flash';
import Icon from '../assets/icons/base';
import FieldSet from '../components/forms/FieldSet';

import { signup } from '../utils/api/users';
import { SET_CURRENT_USER, STORE_PATH } from '../reducers/Auth';

import styleGuide from '../assets/styleGuide';
import AuthScreenNav from '../components/navbar/AuthScreen';
const { distance, colors } = styleGuide;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function SignupScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedPath = useSelector(state => state.storedPath);

  const [backendErrors, setBackendErrors] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disableReason, setDisableReason] = useState('Fill out all fields');
  const [formState, setFormState] = useState({ name: '', email: '', password: '', passwordConfirm: '' });

  const onSubmit = async () => {
    const res = await signup(formState);

    if (res.errors) {
      setBackendErrors(res.errors);
    } else {
      dispatch({ type: SET_CURRENT_USER, value: res });
      let path = '/';
      if (storedPath) {
        path = storedPath;
        dispatch({ STORE_PATH, value: null });
      }
      navigate(path)
    }
  };

  const handleChange = (field, val) => {
    const newFormState = { ...formState };

    newFormState[field] = val;
    setFormState(newFormState);
    toggleButtonDisabled(newFormState);
  }

  const toggleButtonDisabled = (newFormState) => {
    const { email, password, passwordConfirm } = newFormState;

    let disableText = [];
    if (!validateEmail(email)) {
      setButtonDisabled(true);
      disableText.push('Double check your email');
    }

    if (password.length < 6) {
      setButtonDisabled(true);
      disableText.push('Password too short');
    } else if (password !== passwordConfirm) {
      setButtonDisabled(true);
      disableText.push('Passwords don\'t match');
    }

    disableText.length === 0
      ? setButtonDisabled(false)
      : setDisableReason(disableText.join('\n'));
  }

  return (
    <View>
      <AuthScreenNav />

      <ScrollView style={styles.container} keyboardShouldPersistTaps="handled">

        <Icon fileName="shield_blue" addedStyles={{ alignSelf: 'center', marginBottom: distance.large }} />

        {backendErrors && <Flash messages={backendErrors} onExit={() => setBackendErrors(null)} />}

        <FieldSet
          name="name"
          required={true}
          placeholder="Luke Skywalker"
          value={formState['name']}
          label="Name"
          handleChange={(val) => handleChange('name', val)}
          autoFocus={true}
        />

        <FieldSet
          name="email"
          required={true}
          placeholder="Luke Skywalker"
          value={formState['email']}
          label="Email"
          handleChange={(val) => handleChange('email', val)}
          autoFocus={false}
        />

        <FieldSet
          name="password"
          required={true}
          placeholder="Luke Skywalker"
          value={formState['password']}
          label="Password"
          type="password"
          handleChange={(val) => handleChange('password', val)}
          autoFocus={false}
        />

        <FieldSet
          name="passwordConfirm"
          required={true}
          placeholder="Luke Skywalker"
          value={formState['passwordConfirm']}
          label="Password Confirm"
          type="password"
          handleChange={(val) => handleChange('passwordConfirm', val)}
          autoFocus={false}
        />

        <Button
          async
          title="Sign Up"
          onPress={onSubmit}
          addedStyles={{ backgroundColor: colors.green, marginBottom: distance.large }}
          disabled={buttonDisabled}
          disabledText={disableReason}
        />

      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 20,
  },
});
