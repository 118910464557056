import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M1 .25a.75.75 0 1 0 0 1.5V.25ZM5 1l.735-.147A.75.75 0 0 0 5 .25V1Zm2.68 13.39.736-.146v-.001l-.736.147Zm2 1.61v-.75h-.014l.014.75Zm9.72 0 .014-.75H19.4V16Zm2-1.61.736.146v-.005l-.736-.141ZM23 6l.737.14A.75.75 0 0 0 23 5.25V6ZM6 5.25a.75.75 0 0 0 0 1.5v-1.5ZM9.25 21a.25.25 0 0 1-.25.25v1.5A1.75 1.75 0 0 0 10.75 21h-1.5Zm-.25.25a.25.25 0 0 1-.25-.25h-1.5c0 .966.784 1.75 1.75 1.75v-1.5ZM8.75 21a.25.25 0 0 1 .25-.25v-1.5A1.75 1.75 0 0 0 7.25 21h1.5Zm.25-.25a.25.25 0 0 1 .25.25h1.5A1.75 1.75 0 0 0 9 19.25v1.5Zm11.25.25a.25.25 0 0 1-.25.25v1.5A1.75 1.75 0 0 0 21.75 21h-1.5Zm-.25.25a.25.25 0 0 1-.25-.25h-1.5c0 .966.784 1.75 1.75 1.75v-1.5Zm-.25-.25a.25.25 0 0 1 .25-.25v-1.5A1.75 1.75 0 0 0 18.25 21h1.5Zm.25-.25a.25.25 0 0 1 .25.25h1.5A1.75 1.75 0 0 0 20 19.25v1.5Zm-19-19h4V.25H1v1.5Zm3.265-.603 2.68 13.39 1.47-.294L5.735.853l-1.47.294Zm2.68 13.39a2.75 2.75 0 0 0 .972 1.605l.94-1.168a1.25 1.25 0 0 1-.441-.73l-1.472.292Zm.972 1.605a2.75 2.75 0 0 0 1.777.608l-.028-1.5a1.25 1.25 0 0 1-.808-.276l-.94 1.168Zm1.763.608h9.72v-1.5H9.68v1.5Zm9.706 0a2.75 2.75 0 0 0 1.777-.608l-.94-1.168a1.25 1.25 0 0 1-.809.276l-.028 1.5Zm1.777-.608a2.75 2.75 0 0 0 .973-1.606l-1.472-.292a1.25 1.25 0 0 1-.442.73l.94 1.168Zm.974-1.611 1.6-8.39-1.474-.282-1.6 8.39 1.474.281ZM23 5.25H6v1.5h17v-1.5Z"
      fill="#1A1A1A"
    />
  </Svg>
)

export default SvgComponent
