import React from 'react';

import BaseScreen from '../components/screen_specific/BaseScreen';
import Body from '../components/screen_specific/notifications/Index';

export default function Notifications() {
  return (
    <BaseScreen
      component={Body}
      addPaddingTopToMainContainer={false}
    />
  );
}
