import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native-web';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button';
import Flash from '../components/Flash';
import Icon from '../assets/icons/base';
import FieldSet from '../components/forms/FieldSet';

import { resetPassword } from '../utils/api/users';

import styleGuide from '../assets/styleGuide';
import languageSpecificText from '../helpers/languages';
import AuthScreenNav from '../components/navbar/AuthScreen';
const { distance } = styleGuide;

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export default function ResetPassword() {
  const navigate = useNavigate();

  const [backendErrors, setBackendErrors] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disableReason, setDisableReason] = useState('Missing required fields');
  const [formState, setFormState] = useState({ email: '', token: '', password: '', passwordConfirm: '' });

  const [success, setSuccess] = useState(false);

  const onSubmit = async () => {
    const res = await resetPassword(formState);

    if (res.success === true) {
      setBackendErrors(null);
      setSuccess(true);
      setTimeout(() => {
        navigate('/login')
      }, 200)
    } else {
      setBackendErrors([res.message]);
      setSuccess(false);
    }
  };

  const handleChange = (field, val) => {
    const newFormState = { ...formState };
    newFormState[field] = val;

    if (!validateEmail(newFormState.email)) {
      setDisableReason("Double check your email");
      setButtonDisabled(true);
    } else if (newFormState.token.length === 0) {
      setDisableReason("Missing token");
      setButtonDisabled(true);
    } else if (newFormState.password.length === 0) {
      setDisableReason("Missing password");
      setButtonDisabled(true);
    } else if (newFormState.password !== newFormState.passwordConfirm) {
      setDisableReason("Passwords don't match");
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }

    setFormState(newFormState);
  }

  return (
    <View>
      <AuthScreenNav />

      <View style={styles.container} keyboardShouldPersistTaps="handled">

        <Icon fileName="shield_blue" addedStyles={{ alignSelf: 'center', marginBottom: distance.small }} />

        {backendErrors && <Flash messages={backendErrors} onExit={() => setBackendErrors(null)} />}
        {success && <Flash success={true} messages={["Successfully reset password"]} onExit={() => setBackendErrors(null)} />}

        <FieldSet
          name="email"
          required={true}
          placeholder="luke@star.wars"
          value={formState.email}
          label="Email"
          handleChange={(val) => handleChange('email', val)}
        />
        <FieldSet
          name="token"
          required={true}
          placeholder="*************"
          value={formState.token}
          label="Secret Token"
          handleChange={(val) => handleChange('token', val)}
          type="password"
        />
        <FieldSet
          name="password"
          required={true}
          placeholder="*************"
          value={formState.password}
          label="New Password"
          handleChange={(val) => handleChange('password', val)}
          type="password"
        />
        <FieldSet
          name="passwordConfirm"
          required={true}
          placeholder="*************"
          value={formState.passwordConfirm}
          label="Password Confirm"
          handleChange={(val) => handleChange('passwordConfirm', val)}
          type="password"
        />
        <Button
          async
          title={languageSpecificText('auth', 'resetPassword')}
          onPress={onSubmit}
          addedStyles={{ backgroundColor: styleGuide.colors.green, marginBottom: styleGuide.distance.xlarge }}
          disabled={buttonDisabled}
          disabledText={disableReason}
          tooltipPlacement="top"
        />

      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: styleGuide.colors.white,
    height: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 20,
  },
});
