import * as React from "react"
import Svg, { Path } from "react-native-svg"

const SvgComponent = (props) => (
  <Svg
    width={25}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M9.24 17.467a.75.75 0 0 0 1.056 1.066l-1.055-1.066ZM15.825 12l.528.533a.75.75 0 0 0 0-1.066l-.528.533Zm-5.528-6.533a.75.75 0 1 0-1.055 1.066l1.055-1.066Zm0 13.066 6.056-6-1.056-1.066-6.055 6 1.055 1.066Zm6.056-7.066-6.056-6-1.055 1.066 6.055 6 1.056-1.066Z"
      fill="#1A1A1A"
    />
  </Svg>
)

export default SvgComponent
